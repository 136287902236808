import React from 'react';

import Avatar from "../Avatar";
import './styles.scss';

const DATA = [
  {
    description: 'What I appreciate in working with Co.H: that you take the ownership, you\'re experienced, and that we can rely on you.',
    member: {
      avatar: 'molli.jpg',
      fullName: 'Quốc Nguyễn',
      position: 'Founder, Molli Printing House'
    },
    logo: 'molli.png',
  },
  {
    description: 'Co,H has been the best agency we\'ve worked with so far. Your team understands Houzing and is able to design new skills, features, and interactions within our model.',
    member: {
      avatar: 'furniture.jpg',
      fullName: 'Bách',
      position: 'Co-Founder, Houzing'
    },
    logo: 'houzzze.svg',
  },
  {
    description: 'Let me put it this way: we have built a grand and impressive building. But without Co.H insights, we would be stuck on the ground-floor forever.',
    member: {
      avatar: 'videostreamshopping.jpg',
      fullName: 'Trang',
      position: 'Founder, Video Stream Shopping'
    },
    logo: 'videostreamshopping.svg',
  }
];


const TestimonialsComponent = () => {
  return (
    <React.Fragment>
      <section className="section section--testimonials" id="testimonials">
        <h1 className="border border--center">Trust is a foundation for great products</h1>
        <p className="max__width--medium text--center">
          Our clients often cooperate with us for many years, launching various products. It's because we always
          strive to become trusted technological partners who care to provide more than just software development
          expertise. Thanks to that, we work as an extension of our clients’ teams rather than an outsourcing
          agency.
        </p>
      </section>
      <div className="testimonials__content bg--grayLight">
        {
          DATA.map((item, index) => (
            <div className="card" key={index}>
              <div className="card__header">
                <img src={`/images/logo/${item.logo}`} alt="Logo"/>
              </div>
              <p>{item.description}</p>
              <div className="card__footer">
                <Avatar avatar={item.member.avatar} size={40} />
                <div className="m__l--10">
                  <b>{item.member.fullName}</b>
                  <p>{item.member.position}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </React.Fragment>
  )
};

export default TestimonialsComponent;
