import React, { PureComponent } from 'react';

import { IOS_DATA } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import EstimationComponent from "../../components/Estimation";
import FeaturesComponent from "../../components/Features";
import ProjectComponent from "../../components/Project";
import BannerComponent from "../../components/Banner";

class IOS extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={IOS_DATA.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">iOS development team</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Apple's iOS is one of the most advanced and popular mobile app platforms, with millions of applications
                available through the app store. The iPhone is a great platform to develop on and distribute your mobile
                application. Co.H is a full-service iOS app development company with an experienced team and in-depth
                technical expertise to develop iOS apps that help enterprises stay ahead in the market.
              </p>
              <p className="text--gray m__b--10">
                Our team of highly skilled and experienced iOS developers uses native software technology (Swift
                programming) because they truly believe this method provides improved application development and better
                fulfillment of customers' needs. Co.H team of iOS app developers will assist you in every stage of
                developing a native application.
              </p>
              <p className="text--gray m__b--10">
                Swift is the leading programming language for iOS app development. The flexibility and scalability of
                native development allow our experienced team to build beautiful, scalable apps with the best possible
                performance. The variety of functions served by native libraries enables us to create cutting-edge apps
                without compromising on quality. Third party libraries allow us to speed up development and concentrate
                on the business value of the project.
              </p>
              <p className="text--gray m__b--10">
                Co.H provides services that cover the entire development cycle, from concept to market delivery, all
                catered to your specific goal. Our iOS development team’s comprehensive approach to iPhone app
                development ensures your digital product will be a success. We design, develop and launch iOS
                applications that deliver consistent value at every step of the development lifecycle, including
                prototyping, application architecture, and UI/UX experience.
              </p>
            </div>
            <ProjectComponent data={IOS_DATA.PROJECT}/>
          </section>

          <section className="section">
            <h1 className="border border--center">Reasons to work with us</h1>
            <FeaturesComponent data={IOS_DATA.FEATURES}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={IOS_DATA.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default IOS;
