import React, { PureComponent } from 'react';

import { UX_REVIEW } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import ProjectComponent from "../../components/Project";
import EstimationComponent from "../../components/Estimation";

class UXReview extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={UX_REVIEW.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent data={UX_REVIEW.SUB_MENU}/>
          <section className="section section--overview" id="process">
            <h1 className="border border--center">UX Review process</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                UX Review is an affordable and fast way to radically improve your product. UX design team will help you
                put your app on the right track and create a competitive product that meets your business goals and
                expands your market reach. We will assess the current state of your product and see what's hampering
                your business success.
              </p>
              <p className="text--gray m__b--10">
                The review is a professional user behaviour analysis that compares the results with the industry's
                established and successful standards to determine if: the app is usable and the critical user paths are
                flawless; the interface is visually appealing, intuitive and usable; the content is well-suited to the
                intended target users; and the app's language is free of jargon.
              </p>
              <p className="text--gray m__b--10">
                You will receive a comprehensive data-driven report assessing your app's potential of meeting your
                current and future needs. Our focus is on whether your app: meets your business goals; is market-ready;
                fits the market in terms of scope and range; meets the needs of the user/customer base; is reliable and
                flexible enough to scale up; and supports a road-map for future improvements.
              </p>
            </div>
            <FeaturesComponent alignLeft data={UX_REVIEW.FEATURES}/>
            <div className="special__box">
              <div className="box__left">
                <h3 className="border border--left">Benefits of a UX Review</h3>
                <p>
                  Spot all issues that could have a negative impact on your product and increase its business value.
                </p>
              </div>
              <div className="box__right">
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Re-evaluate your product’s go-to-market strategy and find out whether it can actually be executed on
                    the market.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Compare your product to your competitors’ and find out whether the interests of stakeholders align.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    End up with a clear and simple roadmap of your next steps and redcuce the costs of software
                    development.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Work out a sharper and cleaner value proposition, forcused on the most important ( and verified! )
                    customers needs.
                  </p>
                </div>
              </div>
            </div>
            <ProjectComponent data={UX_REVIEW.PROJECT}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={UX_REVIEW.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default UXReview;
