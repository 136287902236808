import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import SocialNetwork from "./components/SocialNetwork";

import './assets/styles/index.scss';

const MENU = [
  {
    name: 'Services',
    path: 'services'
  },
  {
    name: 'Clients',
    path: 'clients'
  },
  {
    name: 'About us',
    path: 'about-us'
  },
  {
    name: 'Blogs',
    path: 'blogs'
  },
  {
    name: 'Careers',
    path: 'careers'
  },
  {
    name: 'Estimate Project',
    path: 'estimation'
  },
];

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      pageActive: '',
      isScroll: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    this.setState({ isScroll: currentScrollPos > window.innerHeight});
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  toggleMenu = () => {
    this.setState({isOpenMenu: !this.state.isOpenMenu})
  };

  goToPage = (pageActive) => {
    this.setState({
      pageActive,
      isOpenMenu: false
    })
  }



  render() {
    const {
      isOpenMenu,
      pageActive,
      isScroll
    } = this.state;

    return (
      <div className={`wrapper ${isScroll ? 'wrapper--scroll' : ''} ${isOpenMenu ? 'wrapper--menu' : ''}`}>
        <div className={`header--mobile ${isOpenMenu ? 'header--mobile--open' : ''}`}>
          <Link to="/" className="logo__box">
            <i className="logo icon-logo"/>
          </Link>
          <button className={`btn--menu ${isOpenMenu ? 'btn--menu--open' : ''}`} onClick={this.toggleMenu}>
            <span/><span/><span/>
            <span/><span/><span/>
          </button>
        </div>
        <div className={`menu--mobile ${isOpenMenu ? 'menu--mobile--open' : ''}`}>
          <ul className="menu__inner">
            {
              MENU.map((item, index) => {
                return (
                  <li key={index} className={`menu__item ${item.path === pageActive ? 'menu__item--active' : ''}`}
                      onClick={() => this.goToPage(item.path)}>
                    <Link to={`/${item.path}`}>{item.name}</Link>
                  </li>
                )
              })
            }
          </ul>
          <div className="menu__footer">
            <SocialNetwork/>
            <ul className="company__info">
              <a href="mailto:hello@coh.dev">hello@coh.dev</a>
              <a href="tel:+84334542911">+84 33454 2911</a>
              <a href="https://goo.gl/maps/zdcRuHgVSmuWCcLA6">
                26 Đường 4, F.Linh Chiểu, Q.Thủ Đức, Hồ Chí Minh
              </a>
              <a href="/">coh.dev</a>
            </ul>
            <a href="">
              <b className="font__size--small text--gray">Terms & Conditions</b>
            </a>
            <a href="">
              <b className="font__size--small text--gray">Privacy</b>
            </a>
            <p className="font__size--small text--gray">Co.H, 2019. All rights serves</p>
          </div>
        </div>
        {this.props.children}
        {
          isScroll &&
          <button className="btn--scroll__top" onClick={this.scrollToTop}>
            <i className="icon-chevron-down"/>
          </button>
        }
        <Footer/>
      </div>
    );
  }
}

export default App;
