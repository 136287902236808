import React, { PureComponent } from 'react';

import { DEVOPS } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class DevOps extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={DEVOPS.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent />
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Devops team</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Devops is one of the essential parts of every project developed at Co.H. To meet the expectations of our
                clients we use the latest devops tools such as CircleCI, Docker, Logmatic.io, Terraform and Ansible to
                save Infrastructure-as-a-Code. These technologies enable us to maintain environment consistency and high
                predictability throughout the entire development and deployment process. As a result we can spot all
                potential issues before they happen.
              </p>
              <p className="text--gray m__b--10">
                Our devops team makes sure that the infrastructure we use to develop apps is scalable. We use many
                external platforms, such as Amazon Web Services, Google Cloud Platform, DigitalOcean and Heroku to fulfill
                our clients’ needs, from cloud computing, storage, and content delivery to database and DNS management.
                Our team also includes certified AWS Solutions Architect Associates.
              </p>
              <p className="text--gray m__b--10">
                Every pull request automatically creates an isolated “integration” environment with its own database. This
                allows developers and QA engineers to instantly preview the delivered code before it is merged with one of
                the main branches. Using this workflow simplifies and facilitates the development and testing processes.
              </p>
              <p className="text--gray m__b--10">
                Constant monitoring is essential to our work. We are immediately notified if traffic on your website
                suddenly increases. As a result we can intervene before the app crashes and avoid any downtime. We
                leverage battleground-proven solutions, such as Pagerduty, DataDog, custom Sensu checks and Pingdom.
              </p>
              <p className="text--gray m__b--10">
                Finally, we have a dedicated 24/7 emergency team that answers calls from Pagerduty and reacts in crisis
                situations. Day or night, we always keep our finger on the pulse.
              </p>
            </div>
            <FeaturesComponent alignLeft data={DEVOPS.FEATURES}/>
          </section>

          <IndustriesComponent />
          <TestimonialsComponent />
          <EstimationComponent data={DEVOPS.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default DevOps;
