import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import HomePage from "./pages/Home";
import ServicesPage from "./pages/Services";
import ClientsPage from "./pages/Clients";
import AboutUsPage from "./pages/AboutUs";
import BlogPage from "./pages/Blogs";
import CareersPage from "./pages/Careers";
import EstimationPage from "./pages/Estimation";

import ProductDesignSprint from "./sub-pages/ProductDesignSprint";
import ProgressiveWebApps from "./sub-pages/ProgressiveWebApps";
import DevOps from "./sub-pages/DevOps";
import ScopingSession from "./sub-pages/ScopingSession";
import QualityAssurance from "./sub-pages/QualityAssurance";
import IOS from "./sub-pages/IOS";
import Android from "./sub-pages/Android";
import MachineLearning from "./sub-pages/MachineLearning";
import UXReview from "./sub-pages/UXReview";
import DigitalTransformation from "./sub-pages/DigitalTransformation";
import ProductDesign from "./sub-pages/ProductDesign";
import ReactJs from "./sub-pages/ReactJs";
import ReactNative from "./sub-pages/ReactNavite";
import BusinessIntelligence from "./sub-pages/BusinessIntelligence";
import UXServices from "./sub-pages/UXServices";
import NodeJs from "./sub-pages/NodeJs";
import Flutter from "./sub-pages/Flutter";
import Illustrations from "./sub-pages/Illustrations";
import DataAnnotation from "./sub-pages/DataAnnotation";

const routes = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/clients',
    component: ClientsPage
  },
  {
    path: '/about-us',
    component: AboutUsPage
  },
  {
    path: '/blogs',
    component: BlogPage
  },
  {
    path: '/careers',
    component: CareersPage
  },
  {
    path: '/estimation',
    component: EstimationPage
  },
];

const servicesRoutes = [
  {
    path: '/services',
    component: ServicesPage
  },
  {
    path: '/services/product-design-sprint',
    component: ProductDesignSprint
  },
  {
    path: '/services/product-design',
    component: ProductDesign
  },
  {
    path: '/services/progressive-web-apps',
    component: ProgressiveWebApps
  },
  {
    path: '/services/dev-ops',
    component: DevOps
  },
  {
    path: '/services/scoping-session',
    component: ScopingSession
  },
  {
    path: '/services/quality-assurance',
    component: QualityAssurance
  },
  {
    path: '/services/machine-learning',
    component: MachineLearning
  },
  {
    path: '/services/ux-review',
    component: UXReview
  },
  {
    path: '/services/ux-services',
    component: UXServices
  },
  {
    path: '/services/digital-transformation',
    component: DigitalTransformation
  },
  {
    path: '/services/business-intelligence',
    component: BusinessIntelligence
  },
  {
    path: '/services/ios',
    component: IOS
  },
  {
    path: '/services/android',
    component: Android
  },
  {
    path: '/services/react-js',
    component: ReactJs
  },
  {
    path: '/services/react-native',
    component: ReactNative
  },
  {
    path: '/services/node-js',
    component: NodeJs
  },
  {
    path: '/services/flutter',
    component: Flutter
  },
  {
    path: '/services/illustrations',
    component: Illustrations
  },
  {
    path: '/services/data-annotation',
    component: DataAnnotation
  },
];

ReactDOM.render(
  <BrowserRouter>
    <App>
      {
        routes.map((route) => (
          <Route exact key={route.path} path={route.path} component={route.component}/>
        ))
      }
      <Switch>
        {
          servicesRoutes.map((route) => (
            <Route exact key={route.path} path={route.path} component={route.component} />
          ))
        }
      </Switch>
    </App>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
