import React, { PureComponent } from 'react';

import { DATA_ANNOTATION } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import ProjectComponent from "../../components/Project";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class DataAnnotation extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={DATA_ANNOTATION.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Data annotation is time-consuming but worth the trouble </h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                You might have heard the saying that quality data is the new oil. There’s so much value hidden in
                images, sound, video, and text. All you need to do is tap it.
              </p>
            </div>
            <FeaturesComponent data={DATA_ANNOTATION.FEATURES}/>
            <ProjectComponent data={DATA_ANNOTATION.PROJECT}/>
          </section>

          <section className="section">
            <h1 className="border border--center">
              Make your data meaningful - the process of data labeling and classification
            </h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                The process of annotation is most dependably done by human beings. It might sound low-tech, but it’s
                not that easy. Several steps need to be followed with precision and focus.
              </p>
            </div>
            <FeaturesComponent data={DATA_ANNOTATION.FEATURES_2}/>
          </section>

          <section className="section">
            <h1 className="border border--center">Leverage your labeled data</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Now you can use the data for your ML project to create models through supervised learning.
                Your machine learning app will use the annotated data set and learn how to categorise and define
                elements, thanks to the magic of deep learning and learning algorithms.
              </p>
              <p className="text--gray m__b--10">
                Take a look at Carlens, our data annotation project, to see the potential results in action. We used
                10,923 annotated photos as training data.
              </p>
            </div>
          </section>

          <section className="section">
            <h1 className="border border--center">Ideation & Evaluation - best way to kick off your product idea</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Whether you need to craft the idea for a completely new machine learning product or evaluate the quality
                of an existing data science project - we're here to help you. Our Machine Learning experts will be happy
                to assist you in building a solid plan for your business.
              </p>
            </div>
          </section>

          <section className="section">
            <h1 className="border border--center">What types of data can we annotate?</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                In our multi-channel reality, one type of data might not be enough. Consider what data sets can do the
                most for your business. You can annotate all of the major data types on our platform, on various
                classification levels.
              </p>
            </div>
            <FeaturesComponent data={DATA_ANNOTATION.FEATURES_3}/>
          </section>

          <section className="section">
            <h1 className="border border--center">Build your machine learning project with a trusted partner</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                We’re in love with deep learning and the world of opportunities it opens up before us. Let’s work
                together to make something great, using cutting-edge, top-quality solutions.
              </p>
            </div>
            <FeaturesComponent data={DATA_ANNOTATION.FEATURES_4}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={DATA_ANNOTATION.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default DataAnnotation;
