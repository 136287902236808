import React, { PureComponent } from 'react';

import { CAREERS_PAGE } from "../../constants";

import BannerComponent from "../../components/Banner";

class CareersPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--careers">
        <BannerComponent data={CAREERS_PAGE.BANNER} />
        <div className="mark"/>
        <div className="page__content bg--white"/>
      </div>
    );
  }
}

export default CareersPage;
