import React from 'react';
import './styles.scss';

const Icon = ({isCircle = false, isLink = false, name, className = ''}) => {
  return (
    <div className={`icon ${isCircle ? 'icon--circle' : ''} ${className}`}>
      <i className={`icon-${name}`} />
    </div>
  )
};


export default Icon;
