import React from 'react';

export const FOOTER = [
  {
    title: 'Service',
    urls: [
      {
        name: 'Product Design',
        path: '',
      },
      {
        name: 'Web Application Development',
        path: '',
      },
      {
        name: 'Mobile Application Development',
        path: '',
      },
      {
        name: 'Digital Transformation',
        path: '',
      },
      {
        name: 'Machine Learning',
        path: '',
      },
      {
        name: 'Branding & Illustration',
        path: '',
      },
    ]
  },
  {
    title: 'About us',
    urls: [
      {
        name: 'Our team',
        path: ''
      },
      {
        name: 'Works',
        path: ''
      },
      {
        name: 'Happy clients',
        path: ''
      },
    ]
  },
  {
    title: 'Careers',
    urls: [
      {
        name: 'Opening positions',
        path: ''
      },
      {
        name: 'Cooporate positions',
        path: ''
      }
    ]
  },
  {
    title: 'Contact',
    urls: [
      {
        name: 'hello@coh.dev',
        path: 'hello@coh.dev'
      },
      {
        name: '+84 33454 2911',
        path: '+84 33454 2911',
      },
      {
        name: '26 Đường 4, F.Linh Chiểu, Q.Thủ Đức, Hồ Chí Minh',
        path: ''
      },
      {
        name: 'coh.dev',
        path: 'coh.dev'
      }
    ]
  }
];

export const HOME_PAGE = {
  SERVICES: [
    {
      icon: '/images/icons/product-design.svg',
      title: 'Product Design',
      techList: [
        {
          name: 'Product Design Sprint',
          path: 'services/product-design-sprint'
        },
        {
          name: 'Product Design',
          path: 'services/product-design'
        },
        {
          name: 'UX Review',
          path: 'services/ux-review'
        },
        {
          name: 'Illustration',
          path: 'services/illustration'
        },
        {
          name: 'Branding',
          // path: 'services/branding'
        },
      ]
    },
    {
      icon: '/images/icons/web-application.svg',
      title: 'Web Application',
      techList: [
        {
          name: 'Node.js',
          path: 'services/node-js'
        },
        {
          name: 'React.js',
          path: 'services/react-js'
        },
        {
          name: 'Vue.js',
          // path: 'services/vuejs'
        },
        {
          name: 'Progressive Web Apps',
          path: 'services/progressive-web-apps'
        },
        {
          name: 'Python',
          // path: 'services/python'
        },
      ]
    },
    {
      icon: '/images/icons/mobile-application.svg',
      title: 'Mobile Application',
      techList: [
        {
          name: 'Android',
          path: 'services/android'
        },
        {
          name: 'iOS',
          path: 'services/ios'
        },
        {
          name: 'React Native',
          path: 'services/react-native'
        },
        {
          name: 'Flutter',
          path: 'services/flutter'
        },
      ]
    },
    {
      icon: '/images/icons/digital-transformation.svg',
      title: 'Digital Transformation',
      techList: [
        {
          name: 'Enterprise Web Application',
          // path: 'services/enterprise-web-application'
        },
        {
          name: 'Enterprise Mobile Application',
          // path: 'services/enterprise-mobile-application'
        },
        {
          name: 'Virtual Reality',
          // path: 'services/virtual-reality'
        },
        {
          name: 'Augmented Reality',
          // path: 'services/augmented-reality'
        },
        {
          name: 'Machine Learning & AI',
          path: 'services/machine-learning'
        },

      ]
    }
  ],
  FEATURES: [
    {
      title: 'Ideation',
      icon: 'beer',
      description: 'Sit down and grab a beer, we could talk about your stories!'
    },
    {
      title: 'Consultant',
      icon: 'sign-direction',
      description: 'We take you to the other world of technologies and see how far could we go together there'
    },
    {
      title: 'Planning',
      icon: 'hand',
      description: 'At here, we become partner in crime with your mission and our helping hands'
    },
    {
      title: 'Development & Launch',
      icon: 'rocket',
      description: 'We stand by you when you kick off your product, see how it grow and look at it proudly'
    },
    {
      title: 'Maintainable',
      icon: 'chart',
      description: 'And now, we could grab a beer again ... and just look after it whenever you need us'
    }
  ]
};

export const SERVICES_PAGE = {
  SERVICES: [
    {
      order: '01',
      title: ['Ideation and', <span className="text--secondary"> evaluation</span>],
      description: 'Give your product idea a shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate possible mistakes.',
      background: '',
      project: {
        title: 'Ideation workshop for the latest printing online solution',
        subTitle: 'Online printing services, and marketplace for baby and mom stuffs',
        description: 'Mr.Quoc came up with an idea for everyone who want to save their memories everytime and everywhere,take their photos alive and correct their home, through mobile and web application.He need a team to help he develop the concept and product.',
        image: '/projects/molli.jpg',
        path: '',
      },
      middle: {
        title: 'Best way to kick off your product ideas',
        description: 'No matter you need to craft the idea for a completely new product or evaluate the quality of an already existing one - we\'re here to help you.'
      },
      services: [
        {
          icon: 'cube-unfolded',
          title: 'Product Design Sprint',
          description: 'A five-days workshop that will help you answer crucial business questions, plan the essential features of your product and reduce the risk of bringing a product to the market.',
          path: 'services/product-design-sprint'
        },
        {
          icon: 'compass',
          title: 'Scoping Session',
          description: 'A workshop aimed at shaping your business idea, answering questions regarding project planning, time estimation and budgeting.',
          path: 'services/scoping-session'
        },
        {
          icon: 'eye-circle',
          title: 'UX Review',
          description: 'An evaluation which will help you radically improve your product by eliminating all UX issues to get a truly appealling and streamlined experiance that users will love.',
          path: 'services/ux-review'
        },
        {
          icon: 'console-line',
          title: 'Code Review',
          description: 'A process of review of a web or mobile application’s code aimed at making the product be more secure and work flawlessly and faster thanks to the bulletproof backend.',
        }
      ],
      quote: {
        title: 'Companies about our Ideation & evaluation services',
        description: 'See what our clients say about the way our Ideation & evaluation services helped them leverage their business potential.',
        listing: [
          {
            description: 'Let me put it this way: we have built a grand and impressive building. But without Co.H\'s insights from the Product Design Sprint, we would be stuck on the ground-floor forever. Now we definitely feel it might be the future of printing.',
            logo: 'molli.png',
            member: {
              avatar: 'molli.jpg',
              fullName: 'Quốc Nguyễn',
              position: 'Founder, Molli Printing House'
            }
          },
          {
            description: 'The UX Review was really helpful in giving us ideas on what direction we should be taking, and what mistakes we had made while going through the design process the first time. It was a genuinely useful overview of the whole product.',
            logo: 'videostreamshopping.svg',
            member: {
              avatar: 'videostreamshopping.jpg',
              fullName: 'Trang',
              position: 'Founder, Video Stream Shopping'
            }
          },
          {
            description: 'Getting an outsider\'s perspective was crucial in discovering our weaknesses. With multiple suggestions offered for drastic improvements, we removed unnecessary features, explored better ways to communicate our core functionality, and prioritised a redesign to take advantage of newer opportunities.',
            logo: 'houzzze.svg',
            member: {
              avatar: 'furniture.jpg',
              fullName: 'Bách',
              position: 'Co-Founder, Houzzze'
            }
          }
        ]
      }
    },
    {
      order: '02',
      title: ['Product', <span className="text--secondary"> design</span>],
      description: 'Craft delightful user experiences for your digital products. Solve real problems and improve your vital business metrics through beautiful interfaces.',
      background: '',
      project: {
        title: 'Challenging to transformation Ecommerce into Social Livestream Network',
        subTitle: 'Video Streaming integrated with Ecommerce, Make Shopping more emgagement and fun',
        description: 'Trang told us wanted to create a fashion application that would boost women\'sconfidence with luxury collections as well as raise societal and environmental awareness.That’s how the idea for the e-commerce platform with stylish clothes integrated with Live Stream platform.',
        image: '/projects/videostreamshopping.jpg',
        path: ''
      },
      middle: {
        title: 'Great design is a key to success',
        description: 'Product Design stives to deliver beautiful anu usable products that solve user problems and help you achiveachive your business goals.'
      },
      services: [
        {
          icon: 'layers-triple',
          title: 'User Interface Design',
          description: 'Craft beautiful and engaging user interface that impress your customer from the first moment.',
          path: 'services/product-design'
        },
        {
          icon: 'target',
          title: 'User Experience Design',
          description: 'Humanise your software user experience and provide additional value to your customers and your business.',
          path: 'services/ux-services'
        },
        {
          icon: 'auto-fix',
          title: 'Illustrations',
          description: 'Establish a unique and unmistakable visual language of your brand and product through customer-built illustrations.',
          path: 'services/illustrations'
        }
      ],
      quote: {
        title: 'Companies about our Ideation & evaluation services',
        description: 'See what our clients say about the way our Ideation & evaluation services helped them leverage their business potential.',
        listing: [
          {
            description: 'The design phase was excellent. From the start, we applied comprehensive thinking. The designers understood our user needs perfectly.',
            logo: 'mom.svg',
            member: {
              avatar: 'mom.jpg',
              fullName: 'Hoà',
              position: 'Founder, MoM Cooking Class'
            }
          },
          {
            description: 'I was impressed by the designer\'s work. He was able to expand our idea, and transform the few screens we had to the full interfaces on both Android and iOS.',
            logo: 'berrys.png',
            member: {
              avatar: 'berrys.jpg',
              fullName: 'Nick',
              position: 'Founder, Berrys'
            }
          },
          {
            description: 'Graphic design is fresh, clean and different from competitor platforms. I like the UX a lot. [...] With Platform that Co.H build for us, we show much more relevant information in the thumbnails of each candidate.',
            logo: 'huyminh.svg',
            member: {
              avatar: 'huyminh.jpg',
              fullName: 'Mss.Hà',
              position: 'Founder, Huy Minh Realestate'
            }
          }
        ]
      }
    },
    {
      order: '03',
      title: [<span className="text--secondary">Web Application </span>, 'development'],
      description: 'Create beautiful, fast and secure web applications tailored exclusively for your business goals.',
      background: '',
      project: {
        title: 'Development Building Rental for Professional Agency',
        subTitle: 'Huy Minh Realestate - Building Agency',
        description: 'Rental building of Mss.Hà was been using in the traditional way with MS.Excel and MS.Words. She need to innovate up the business model and scale it up. She required a system that could help automate rental request from customer and build up contracts. And other department could use it to tracking and communicate.',
        image: '/projects/huyminh.jpg',
        path: ''
      },
      middle: {
        title: 'Build your Web App with us',
        description: 'Leverage reliable methodologies and best practices in development.'
      },
      services: [
        {
          icon: 'nodejs',
          title: 'Node.js',
          description: 'Build performance web applications based on JavaScript - one language for full-stack development.',
          path: 'services/node-js'
        },
        {
          icon: 'react',
          title: 'React.js',
          description: 'Implement beautiful and responsive user interfaces that are highly-interactive and give user a native-like experience.',
          path: 'services/react-js'
        },
        {
          icon: 'application',
          title: 'Progressive Web App',
          description: 'Build a progressive web app application that uses the latest modern web technologies to deliver impressive mobile user experience.',
          path: 'services/progressive-web-apps'
        },
        {
          icon: 'python',
          title: 'Python',
          description: 'Python is currently one of the fastest growing languages to get your job done on time.',
        }
      ],
      quote: {
        title: 'Companies about our Ideation & evaluation services',
        description: 'See what our clients say about the way our Ideation & evaluation services helped them leverage their business potential.',
        listing: [
          {
            description: 'We’ve got a great relationship with Co.H. It feels like they’re part of our team, not an outsourcing agency, and the strength of the relationship we have built is outstanding. Their work has exceeded my expectations by far, both in terms of quality and speed. Their ideas and commitment to our project are notable.',
            logo: 'saigonzoo.svg',
            member: {
              avatar: 'saigonzoo.jpg',
              fullName: 'Mrs.Ngọc',
              position: 'Operation Manager, Saigon Zoo & Garden'
            }
          },
          {
            description: 'Building a platform that we can easily scale up was challenging. We needed exceptional engineering capacities (...) We had to switch to Ruby on Rails and the Co.H team helped us do that. They built a solid base for our further growth. Now we can focus on building new features and growing the product further and further.',
            logo: 'movielandingpage.svg',
            member: {
              avatar: 'movie.jpg',
              fullName: 'Nathaniel',
              position: 'Chief Digital Officer, Movie Landing Page Studio'
            }
          },
          {
            description: 'We have been hugely impressed with Co.H and consider them an extension of our team!  We are immensely proud of our platform and firmly believe that the quality of design and delivery far exceeds that which we could have implemented with an onshore provider.',
            logo: 'molli.png',
            member: {
              avatar: 'molli.jpg',
              fullName: 'Quốc Nguyễn',
              position: 'Founder, Molli Printing House'
            }
          }
        ]
      }
    },
    {
      order: '04',
      title: [<span className="text--secondary">Mobile Application </span>, 'development'],
      description: 'Build well-designed and optimized custom mobile applications with a delightful UX for both iOS and Android.',
      background: '',
      project: {
        title: 'Making friend with real identification with Linkedin style application',
        subTitle: 'We develop mobile application for Berry within 2 months',
        description: 'Berrys asked Co.H to create an online service for searching for crushes and interacting between contacts.',
        image: '/projects/berrys.jpg',
        path: ''
      },
      middle: {
        title: 'Build your Mobile App with us',
        description: 'Get professional support from the mobile team at every stage of development.'
      },
      services: [
        {
          icon: 'apple-ios',
          title: 'iOS',
          description: 'Develop top-quality iOS apps with cutting edge technology.',
          path: 'services/ios'
        },
        {
          icon: 'android',
          title: 'Android',
          description: 'Building beautiful and scalable mobile applications and top performance using the Java and Kotlin programming languages.',
          path: 'services/android'
        },
        {
          icon: 'react',
          title: 'React Native',
          description: 'Leverage a powerful framework for a mobile cross-platform development. One technology, one codebase, multiple devices, fast development.',
          path: 'services/react-native'
        },
        {
          icon: 'flutter',
          title: 'Flutter',
          description: 'Take your idea to the next stage and accelerate ypro product\'s go-to-market with Fluter development.',
          path: 'services/flutter'
        }
      ],
      quote: {
        title: 'Companies about our Ideation & evaluation services',
        description: 'See what our clients say about the way our Ideation & evaluation services helped them leverage their business potential.',
        listing: [
          {
            description: 'Co.H impressed us at the scoping session and met our expectations during the first five months of the project. Team members are really open, very friendly and have quite an outstanding level of English. I judge this from your ability to understand jokes and Co.H team have no problem with this.',
            logo: 'videostreamshopping.svg',
            member: {
              avatar: 'videostreamshopping.jpg',
              fullName: 'Trang',
              position: 'Founder, Video Stream Shopping'
            }
          },
          {
            description: 'Working with the Co.H Team was an amazing experience. They have been very responsive and flexible. We definitely increased the pace of development. We’re now releasing many more features than we used to before we started the co-operation with Co.H.',
            logo: 'huyminh.svg',
            member: {
              avatar: 'huyminh.jpg',
              fullName: 'Mss.Hà',
              position: 'Founder, Huy Minh Realestate'
            }
          },
          {
            description: 'I remember hearing from calls with Co.H\'s reference contacts that the experience and breadth of knowledge of the team was great. The small development team we work with daily is good, but we know other resources within Co.H can be tapped, people with different skill sets. It’s almost like having extra consultants for free, or a support network.',
            logo: 'houzzze.svg',
            member: {
              avatar: 'furniture.jpg',
              fullName: 'Bách',
              position: 'Co-Founder, Houzzze'
            }
          }
        ]
      }
    },
    {
      order: '05',
      title: [<span className="text--secondary">Support </span>, 'and management'],
      description: 'The project may be completed, but the product is never finished. We will secure its continuous quality and support incremental improvements.',
      background: '',
      project: {
        title: 'Growing key tech partnership with best cooking class system',
        subTitle: 'MoM cooking class - Cuisine Cooking Class for foreigners, tourisms and market place for menu recipies',
        description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
        image: '/projects/mom.jpg',
        path: ''
      },
      middle: {
        title: 'Build your product right',
        description: 'It is one thing to deliver a project. To develop it successfully to meet the ever changing market needs it’s another. We are here to support your growth.\n'
      },
      services: [
        {
          icon: 'projector-screen',
          title: 'Project Management',
          description: 'We have a well-laid-out communication scheme and coherent processes. Our Project Manager keeps their finger on the pulse and makes sure the project is delivered on time and within budget.',
        },
        {
          icon: 'ab-testing',
          title: 'Quality Assurance',
          description: 'Quality Assurance specialists are the quite guardians of your project, making sure the product is released bug-free and that it provide users with a flawless experience.',
          path: 'services/quality-assurance'
        },
        {
          icon: 'code-tags',
          title: 'DevOps/SLA',
          description: 'We have rock solid DevOps/SLA practices that we have developed to enhance trust, speed up development, resolve issues, and increase project security.',
          path: 'services/dev-ops'
        },
        {
          icon: 'wrench',
          title: 'Maintainable',
          description: 'We offer development and design enhancements for your existing product or service. We can also keep your infrastructure up to date.',
        }
      ],
      quote: {
        title: 'Companies about our Ideation & evaluation services',
        description: 'See what our clients say about the way our Ideation & evaluation services helped them leverage their business potential.',
        listing: [
          {
            description: 'Co.H has been the best agency we\'ve worked with so far. The team is able to design new skills, features, and interactions within our model, with a great focus on speed to market.',
            logo: 'mom.svg',
            member: {
              avatar: 'mom.jpg',
              fullName: 'Hoà',
              position: 'Founder, MoM Cooking Class'
            }
          },
          {
            description: 'And this is what I appreciate in working with Co.H: that you take the ownership, that you\'re experienced, and that we can rely on you.',
            logo: 'movielandingpage.svg',
            member: {
              avatar: 'movie.jpg',
              fullName: 'Nathaniel',
              position: 'Chief Digital Officer, Movie Landing Page Studio'
            }
          },
          {
            description: 'We have found Co.H to be very professional, proactive and great to work with. They have done a good job of understanding the skills and requirements of our teams and have matched their engineers accordingly.',
            logo: 'berrys.png',
            member: {
              avatar: 'berrys.jpg',
              fullName: 'Nick',
              position: 'Founder, Berrys'
            }
          }
        ]
      }
    },
    {
      order: '06',
      title: ['Machine Learning and', <span className="text--secondary"> Digital Transformation</span>],
      description: 'Machine learning and business intelligence solutions turn your data into a competitive advantage.',
      background: '',
      project: {
        title: 'Learning how people react with 3D modeling selection with new Furniture Ecommerce App',
        subTitle: 'Houzing - Furniture Ecommerce with 3D gallery view.',
        description: 'Houzing is an e-commerce platform that connects customers with the most beautiful crafts. Before contracting Co.H, Houzing was struggling with technical issues surrounding the project\'s online infrastructure.',
        image: '/projects/furniture.jpg',
        path: ''
      },
      middle: {
        title: 'Be ready for technology transformation',
        description: 'We gonna take you a higher look how far when you transform your business into technology.'
      },
      services: [
        {
          icon: 'brain',
          title: 'Machine Learning',
          description: 'Personalize your customer experience, automate your process, and change the way the customers interact with your product.',
          path: 'services/machine-learning'
        },
        {
          icon: 'cloud-sync',
          title: 'Digital Transformation',
          description: 'Digitalized your processes and increase revenues, cut costs, improve security, and build new ways of organizing processes.',
          path: 'services/digital-transformation'
        },
        {
          icon: 'chart',
          title: 'Business Intelligent',
          description: 'Transform data into actionable intelligent that informs an organization\'s strategic and tactical business decisions.',
          path: 'services/business-intelligence'
        },
        {
          icon: 'database',
          title: 'Data Annotation',
          description: 'Annotate data quickly and effectively through the community of professionals on our platform.',
          path: 'services/data-annotation'
        }
      ],
      quote: {
        title: 'Companies about our Ideation & evaluation services',
        description: 'See what our clients say about the way our Ideation & evaluation services helped them leverage their business potential.',
        listing: [
          {
            description: 'Let me put it this way: we have built a grand and impressive building. But without Co.H\'s insights from the Product Design Sprint, we would be stuck on the ground-floor forever. Now we definitely feel it might be the future of the car industry.',
            logo: 'molli.png',
            member: {
              avatar: 'molli.jpg',
              fullName: 'Quốc Nguyễn',
              position: 'Founder, Molling Printing House'
            }
          },
          {
            description: 'The UX Review was really helpful in giving us ideas on what direction we should be taking, and what mistakes we had made while going through the design process the first time. It was a genuinely useful overview of the whole product.',
            logo: 'videostreamshopping.svg',
            member: {
              avatar: 'videostreamshopping.jpg',
              fullName: 'Trang',
              position: 'Founder, Video Stream Shopping'
            }
          },
          {
            description: 'Getting an outsider\'s perspective was crucial in discovering our weaknesses. With multiple suggestions offered for drastic improvements, we removed unnecessary features, explored better ways to communicate our core functionality, and prioritised a redesign to take advantage of newer opportunities.',
            logo: 'houzzze.svg',
            member: {
              avatar: 'furniture.jpg',
              fullName: 'Bách',
              position: 'Co-Founder, Houzzze'
            }
          }
        ]
      }
    }
  ],
  SUB_MENU: [
    {
      id: 'idention',
      name: 'Indention'
    },
    {
      id: 'productDesign',
      name: 'Product Design'
    },
    {
      id: 'webDevelopment',
      name: 'Web Development'
    },
    {
      id: 'mobileDevelopment',
      name: 'Mobile Development'
    },
    {
      id: 'support',
      name: 'Support'
    },
    {
      id: 'machineLearning',
      name: 'Machine Learning'
    }
  ],
  BANNER: {
    mainPage: 'services',
    img: 'background-22.jpg',
    title: ['Bespoke software development solutions', <span className="text--secondaryLight"> for your business</span>],
    description: 'Design. Development. Consultancy.'
  },
  ESTIMATION: {
    title: ['Start your new project with us or take existing one ',
      <span className="text--secondary"> to next level</span>],
    description: '',
    background: 'background-47.jpg',
  }
};

export const CLIENTS_PAGE = {
  CATEGORIES: [
    {
      name: 'Web application'
    },
    {
      name: 'Mobile application'
    },
    {
      name: 'Healthcare'
    },
    {
      name: 'Lifestyle'
    },
    {
      name: 'NGOs'
    },
    {
      name: 'SaaS'
    },
    {
      name: 'Hospitality'
    },
    {
      name: 'E-commerce'
    },
    {
      name: 'Dating'
    },
    {
      name: 'Printing'
    }
  ],
  PROJECTS: [
    {
      path: 'molli',
      name: 'Molli Printing House',
      description: 'Online printing services, and marketplace for baby and mom stuffs',
      image: 'molli.jpg',
      technologies: ['Mobile Application', 'React Native', 'Life Style', 'Online Printing'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Mr.Quoc came up with an idea for everyone who want to save their memories everytime and everywhere, take their photos alive and correct their home, through mobile and web application. He need a team to help he develop the concept and product.',
          listing: [
            'Select photos and edit on mobile',
            'Make it friendly with elderly and non-tech people.',
            'Automatic process to printing vendors for faster printing and delivery progress (same-day delivery).'
          ]
        },
        {
          title: 'PROCESS',
          description: 'Co.H came up with a user-friendly solution for creating a wide range of automated editable photo with presets just like instagram but keep high resoution for printing. People now will be able to build a photobook, frame and postcards in just a few minutes thanks to this app, they just stay at home and get their best memories in hand.',
          listing: [
            'Assembling a full-stack team with great design, frontend and backend skills and using a “design first” process.',
            'Designing an automatic creator for non-editor and breaking down the app building process into easy steps.',
            'Engaging app users through smart notifications.'
          ]
        },
        {
          title: 'RESULT',
          description: 'A revolutionary printing idea brought to life. Molli\' potential use cases include print books, postcards, and a lot more image stuffs white just stay at home or anywhere they just wanna keep memories in a better shapes.',
          listing: [
            'Non-editor people can design books, and rapidly develop them with a cost-effectively.',
            'Apps with automated, interactive communication between user and printing vendors.',
            'Vendors could save time with printing algorithm that integrated in app.'
          ]
        }
      ],
      images: ['background-1.png', 'background-2.png', 'background-3.png', 'background-4.png', 'background-5.png', 'background-6.png'],
      quote: {
        quote: 'User should feel creative, as they are building something',
        avatar: 'molli.jpg',
        name: 'Quốc Nguyễn',
        position: 'Founder',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '3',
          text: 'Modules to work'
        },
        {
          name: 'Hours',
          value: '840',
          text: 'working on'
        },
        {
          name: 'Users',
          value: '6k',
          text: 'in first release'
        },
        {
          name: 'Member',
          value: '4',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    },
    {
      path: 'mom',
      name: 'MoM Cooking Class',
      description: 'Cuisine Cooking Class for foreigners, tourisms and market place for menu recipies',
      image: 'mom.jpg',
      technologies: ['Web Application', 'React Js', 'Life Style', 'Hospatility'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Mr.Quoc came up with an idea for everyone who want to save their memories everytime and everywhere, take their photos alive and correct their home, through mobile and web application. He need a team to help he develop the concept and product.',
          listing: [
            'Select photos and edit on mobile',
            'Make it friendly with elderly and non-tech people.',
            'Automatic process to printing vendors for faster printing and delivery progress (same-day delivery).'
          ]
        },
        {
          title: 'PROCESS',
          description: 'Co.H came up with a user-friendly solution for creating a wide range of automated editable photo with presets just like instagram but keep high resoution for printing. People now will be able to build a photobook, frame and postcards in just a few minutes thanks to this app, they just stay at home and get their best memories in hand.',
          listing: [
            'Assembling a full-stack team with great design, frontend and backend skills and using a “design first” process.',
            'Designing an automatic creator for non-editor and breaking down the app building process into easy steps.',
            'Engaging app users through smart notifications.'
          ]
        },
        {
          title: 'RESULT',
          description: 'A revolutionary printing idea brought to life. Molli\' potential use cases include print books, postcards, and a lot more image stuffs white just stay at home or anywhere they just wanna keep memories in a better shapes.',
          listing: [
            'Non-editor people can design books, and rapidly develop them with a cost-effectively.',
            'Apps with automated, interactive communication between user and printing vendors.',
            'Vendors could save time with printing algorithm that integrated in app.'
          ]
        }
      ],
      images: ['background-1.jpg', 'background-2.jpg', 'background-3.jpg'],
      quote: {
        quote: 'Co.H is a great partner. They allowed me and my team to focus on further developing our product line. They managed the entire development process, delivering high quality result. We look forward to further growing our partnership.',
        avatar: 'mom.jpg',
        name: 'Hoà',
        position: 'Founder',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '3',
          text: 'Modules to work'
        },
        {
          name: 'Hours',
          value: '78',
          text: 'working on'
        },
        {
          name: 'Users',
          value: '16k',
          text: '5 stars in TripAdvisor'
        },
        {
          name: 'Member',
          value: '3',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    },
    {
      path: 'huyminh',
      name: 'HM Realestate Agency',
      description: 'Building Rental Services',
      image: 'huyminh.jpg',
      technologies: ['Web Application', 'React Js', 'Hospatility'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Rental building of Mss.Hà was been using in the traditional way with MS.Excel and MS.Words. She need to innovate up the business model and scale it up. She required a system that could help automate rental request from customer and build up contracts. And other department could use it to tracking and communicate.',
          listing: [
            'Learning existing model that she was using and building the way to help it automatic.',
            'High code quality and wide test coverage were essential.',
            'New features and upgrades needed to be introduced quickly.'
          ]
        },
        {
          title: 'PROCESS',
          description: 'Co.H`s team project of 3 (Project Manager, RoR developer and QA specialist) focused on refactoring to give the project bulletproof code with consistent business logic. They introduced a set of tools to monitor the process and ensure that the highest quality would be provided. Their hands-on approach and regular meetings with the client made the process transparent, efficient and consistent with HM Realestate`s goals.',
          listing: [
            'Implementation of multiple changes and new code in React Js Form.',
            'Better prioritisation of tasks and clear, thought-out sprint planning.',
            'Substantial increase in the number of code tests.'
          ]
        },
        {
          title: 'RESULT',
          description: 'HM Realestate has been around for 5 years and continues to serve space rental. Thanks to improving the code structure of the app, its quality rose and the system works fine with no major bugs. Additionally, user onboarding was greatly improved.',
          listing: [
            'Increase in revenue after applying technical fixes.',
            'High-quality and transparent code that is easier to understand and manage, which lowers the app`s maintenance costs.',
            'Improved user onboarding with no blockers in the process.'
          ]
        }
      ],
      images: ['background-1.jpg', 'background-2.jpg', 'background-3.jpg'],
      quote: {
        quote: 'I was positively surprised by Co.H. The handover was much simpler that I had though it was going to be, and the expertise was much better than I was expected.',
        avatar: 'huyminh.jpg',
        name: 'Hà',
        position: 'Founder',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '2',
          text: 'Modules to work'
        },
        {
          name: 'Hours',
          value: '90',
          text: 'working on'
        },
        {
          name: 'Departmenrs',
          value: '3',
          text: 'interact in this model'
        },
        {
          name: 'Member',
          value: '3',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    },
    {
      path: 'berrys',
      name: 'Berrys',
      description: 'Online dating services, matching perfect partner',
      image: 'berrys.jpg',
      technologies: ['Mobile Application', 'React Native', 'Dating', 'Life Style'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Berrys asked Co.H to create an online service for searching for crushes and interacting between contacts.',
          listing: [
            'Make a safe dating service allowing users to stay anonymous.',
            'Help solve the eternal problem of asking out a friend with a simple, safe and streamlined service.',
            'Act within strict time limits and deadlines.'
          ]
        },
        {
          title: 'PROCESS',
          description: 'Creating a beautiful, native app that will completely change the routine of many people who are looking for love.',
          listing: [
            'Creating a well-received gesture-driven interface.',
            'Enabling the community of users to enhance their social lives safely.',
            'Improving the app during the development process.'
          ]
        },
        {
          title: 'RESULT',
          description: 'Berrys is an anonymous, safe and simple service allowing users to find out if the person they like wants to date them.',
          listing: [
            'Carrying out application testing and design implementation.',
            'Complete backend development, making the app user-friendly & safe.',
            'Creating the application from scratch in less than 3 months.'
          ]
        }
      ],
      images: ['background-1.png', 'background-2.png', 'background-3.png', 'background-4.png', 'background-5.png', 'background-6.png', 'background-7.png', 'background-8.png'],
      quote: {
        quote: 'Co.H’s designers understood our user needs and their developers were passionate, skilled and conscientious about the work and project goals. Total partner engagement!',
        avatar: 'berrys.jpg',
        name: 'Nick',
        position: 'Founder',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '1',
          text: 'Modules to work'
        },
        {
          name: 'Hours',
          value: '188',
          text: 'working on'
        },
        {
          name: 'User',
          value: '1k',
          text: 'in first release'
        },
        {
          name: 'Member',
          value: '2',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    },
    {
      path: 'saigonzoo',
      name: 'Saigon Zoo & Garden',
      description: 'Best zoo and garden park in the city',
      image: 'saigonzoo.jpg',
      technologies: ['Web Application', 'React Js', 'NGOs', 'Life Style'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Saigon Zoo & Garden (SZ&G) is a NGO that wanna reinnovation old system, to new generation that could include online tickets, qr paid to check-in, eduation partner with schools. As a part of their growth plans the organisation’s leadership decided to revamp SZ&G’s core application.',
          listing: [
            'Designing and developing new features, including onlike ticket, QR paid to check-in, education partner registration and events booking.',
            'Designing, developing and choosing the right ERP system for the management platform. Implementing it in several department-specific versions.',
            'Implementing a user managenent on their own ticket buying and event bookings.'
          ]
        },
        {
          title: 'PROCESS',
          description: 'Co.H was chosen to develop a platform that allows users to engage and facilitate technology buying and booking. SZ&G was already a complex project operating on a large scale since 2014. On top of that, the project had been developed and maintained by several different teams over the years.',
          listing: [
            'After the initial scoping session, Co.H provided a full stack development team able to jointly work on the development of the platform, as well as the improvement of application’s UI.',
            'We decide to build their own minimum but full feature that needed as an ERP of the SZ&G platform.',
            'The project’s front-end code was reorganised using the BEM approach. A custom Bootstrap style structure was implemented.'
          ]
        },
        {
          title: 'RESULT',
          description: 'Saigon Zoo & Garden is helping more than a thousand schools and ten of thousands of students around the new education partnership and boost their revenue with technology transformation. After years of cooperation, Co.H is still one of its main tech partners.',
          listing: [
            'The Co.H team has created a tailored interface suitable for different cores of features (buying tickets, event bookings, and the partner registrations).',
            'The updated version of the platform’s modules dedicated to educators was released on time and within budget, receiving great feedback from the community.',
            'The cooperation between Co.H and Saigon Zoo & Garden continues.'
          ]
        }
      ],
      images: ['background-1.jpg'],
      quote: {
        quote: 'Co.H engineers had no trouble with code. Their software development process is impressive (...) The Co.H team had to coordinate the work of another remote team responsible for the application. It seemed challenging for me, but Co.H’s PM had no problem with arranging the workflow of the three parties and the co-operation was smooth.',
        avatar: 'saigonzoo.jpg',
        name: 'Mrs.Ngọc',
        position: 'Operation Manager',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '4',
          text: 'Modules to work'
        },
        {
          name: 'Years',
          value: '2',
          text: 'working on'
        },
        {
          name: 'Points',
          value: '4.8',
          text: 'rating on new version'
        },
        {
          name: 'Member',
          value: '4',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    },
    {
      path: 'videostreamshopping',
      name: 'Video Streaming Shopping',
      description: 'Video Streaming integrated with Ecommerce, Make Shopping more emgagement and fun',
      image: 'videostreamshopping.jpg',
      technologies: ['Mobile Application', 'React Native', 'Fashion', 'Life Style'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Trang told us wanted to create a fashion application that would boost women\'s confidence with luxury collections as well as raise societal and environmental awareness. That’s how the idea for the e-commerce platform with stylish clothes integrated with Live Stream platform.',
          listing: [
            'Creating a mobile application where customers can browse distinct collections and buy during the fashiong show live premier.',
            'Implementing a unique experience on the mobile where customers can learn about and vote for a cause of their choice after a purchase.',
            'Make the app design in line with the target group.'
          ]
        },
        {
          title: 'PROCESS',
          description: 'We built and designed the e-commerce mobile from scratch, remaining in close contact with the client, providing frequent updates and recommendations. User experience was also a crucial factor, so the team brought on board an experienced and UX-oriented frontend developer.',
          listing: [
            'Flexible, customisable and easy-to-set-up homepage module.',
            'Great UX throughout the buyer’s journey.',
            'Full spectrum of software consulting throughout the process.'
          ]
        },
        {
          title: 'RESULT',
          description: 'Within 6 months, Co.H\'s team designed a mobile layout with all necessary functionalities and then effortlessly translated them into applications.',
          listing: [
            'E-commerce platform with the necessary functionalities and integrations built in 6 months.',
            'Complete flexibility for the client to manage frontend content, especially for the home page which frequently requires new creative layouts and updates.',
            'Mobile experience just as good as the expectations of clients.'
          ]
        }
      ],
      images: ['background-1.gif', 'background-2.png', 'background-3.png', 'background-4.png', 'background-5.png', 'background-6.png', 'background-7.png'],
      quote: {
        quote: 'I wanted an affordable price without compromising on talent, but I got more than this, a key value of Co.H is transparency and I feld is was a game changer, continuous updates and hornest discussion, resulted in a more efficient and productive development.',
        avatar: 'videostreamshopping.jpg',
        name: 'Trang',
        position: 'Founder',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '3',
          text: 'Modules to work'
        },
        {
          name: 'Months',
          value: '6',
          text: 'working on'
        },
        {
          name: 'Frameworks',
          value: '4',
          text: 'to integrated'
        },
        {
          name: 'Member',
          value: '4',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    },
    {
      path: 'furniture',
      name: 'Houzing - Furniture Ecommerce',
      description: 'Furniture Ecommerce with 3D gallery view.',
      image: 'furniture.jpg',
      technologies: ['Mobile Application', 'React Native', 'Furniture', 'Life Style'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Houzing is an e-commerce platform that connects customers with the most beautiful crafts. Before contracting Co.H, Houzing was struggling with technical issues surrounding the project\'s online infrastructure.',
          listing: [
            'Finding a new partner that would provide a quality assessment of the product.',
            'Fixing the existing code without interfering with current business.',
            'Understanding the clients’ growing needs and successfully upgrading the web application.'
          ]
        },
        {
          title: 'PROCESS',
          description: 'Our client needed a comprehensive review of their product. Another crucial step involved finding new ways of developing and upgrading Houzing\'s e-commerce platform. Co.H provided a full-stack solution to these problems.',
          listing: [
            'Working in record time on an extensive DevOps review of the code.',
            'Pro-actively suggesting new ways of improving the platform.',
            'Helping out with the design, implementation and automation of a new trade channel.'
          ]
        },
        {
          title: 'RESULT',
          description: 'Houzing has grown exponentially, building a community of artists and curators around its business. The platform has been featured in Behance, Dribbble and Funiture magazines.',
          listing: [
            'The web and mobile applications have received very positive feedback from the client and the community.',
            'Houzing has successfully introduced a brand-new, B2B trade channel.',
            'Our client has continued the cooperation with Co.H. Since finishing the original work, we have cooperated on several additional features.'
          ]
        }
      ],
      images: ['background-1.gif', 'background-2.png', 'background-3.png', 'background-4.png', 'background-5.png', 'background-6.png'],
      quote: {
        quote: 'Working with the Co.H team was an amazing experience. They have been responsive and flexible. We defenitely increased the pace of development. We\'re now releasing many more features than we used to before we start co-operation with Co.H.',
        avatar: 'furniture.jpg',
        name: 'Bách',
        position: 'Co-Founder',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '3',
          text: 'Modules to work'
        },
        {
          name: 'Hours',
          value: '480',
          text: 'working on'
        },
        {
          name: 'Glasses',
          value: '416',
          text: 'coffees and beers to finish'
        },
        {
          name: 'Member',
          value: '4',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    },
    {
      path: 'movie',
      name: 'Movie Landing Page',
      description: 'Movie Landing Page, Event Premier Registration',
      image: 'movie.jpg',
      technologies: ['Web Application', 'React Js', 'Nodejs', 'Entertainment'],
      process: [
        {
          title: 'CHALLENGES',
          description: 'Create a digital platform for fiml event premier with an innovative business model, where users can hunt complete first tickets of the big screen movies and the modern way for movie production team prmote their own movie.',
          listing: [
            'Movie Landing wanted to be the first company to create an innovative product (first to market).',
            'A pay-it-forward architecture to design.',
            'An engine for e-tickets conversion to develop.'
          ]
        },
        {
          title: 'PROCESS',
          description: 'We worked closely with Movie Landing and stakeholders. Creating an efficient workflow ready for fast iterations and being able to test the initial product thesis was key.',
          listing: [
            'Establishing a flow with frequent deployments.',
            'Working in close collaboration with the client\'s internal team.',
            'The Algolia search engine (as well as other third-party tools) was introduced to speed up development.'
          ]
        },
        {
          title: 'RESULT',
          description: 'Movie Landing is the first e-tickets for film premier based on the economy of trust. Unlimited events and film ticketes of moives among users is not only allowed but highly encouraged.',
          listing: [
            'The platform was launched in July 2017.',
            'Movie Landing was featured at Philipine Movie Awards in October 2017.',
            'The first e-tickets hunting store where people can easily get their first movie ticket simply and movie production more easier to create theie own movie page to promote.'
          ]
        }
      ],
      images: ['background-1.jpg'],
      quote: {
        quote: 'Working with the Co.H is pure fun. I\'ve never though that buiding new Web products could be so fast and so comfortable. Against a background of experience with other developers, Co.H has been a pleasant surprise. The entire team is open, fexible, interdisciplinary. Everyone understands the whole product process - from planning to the error detection phase. This is priceless.',
        avatar: 'movie.jpg',
        name: 'Nathaniel',
        position: 'Chief Digital Officer',
        background: 'background-21.jpg'
      },
      statistics: [
        {
          name: 'Core',
          value: '1',
          text: 'Modules to work'
        },
        {
          name: 'Month',
          value: '2',
          text: 'working on'
        },
        {
          name: 'User',
          value: '869k',
          text: 'registrations on app'
        },
        {
          name: 'Member',
          value: '3',
          text: 'on board'
        },
      ],
      scopes: [
        {
          name: 'Front-end',
          icon: 'frontend'
        },
        {
          name: 'Back-end',
          icon: 'backend'
        },
        {
          name: 'UI/UX',
          icon: 'ui-ux'
        }
      ]
    }
  ],
  BANNER: {
    mainPage: 'clients',
    img: 'background-51.jpg',
    title: ['Get a world-class agile product team,\n ', <span className="text--secondaryLight"> on demand</span>],
    description: 'At Co.H, we specialize in designing, building, shipping and scaling beautiful, usable products with blazing-fast efficiency.'
  },
  ESTIMATION: {
    title: ['Need help ',
      <span className="text--secondary"> on your project ?</span>],
    description: '',
    background: 'background-52.jpg',
  }
};

export const ABOUT_US_PAGE = {
  BANNER: {
    mainPage: 'about-us',
    img: 'background-12.jpg',
    title: ['One of the fastest-growing', <span className="text--secondaryLight"> software agencies in Vietnam</span>],
    description: 'We design and develop web and mobile applications for our clients worldwide, focusing on outstanding user experience.'
  },
  SUB_MENU: [
    {
      id: 'whoWeAre',
      name: 'Who we are',
    },
    {
      id: 'ourValues',
      name: 'Our Values',
    },
    {
      id: 'howWeWork',
      name: 'How we work',
    },
    {
      id: 'ourTeam',
      name: 'Our Team',
    }
  ],
  STATISTICS: [
    {
      number: '7+',
      title: 'Year of experiences on market'
    },
    {
      number: '11',
      title: 'Projects released so far'
    },
    {
      number: '10',
      title: 'Team members on board'
    },
    {
      number: '3',
      title: 'Successful startup partners'
    }
  ],
  OUR_VALUES: [
    {
      icon: 'arm-flex',
      title: 'Exceed clients’ and colleagues’ expectations',
      description: 'Never settle for "good enough" when you know you can and should do better. It isn’t about measuring your performance against that of others – it’s about giving yourself permission to go the extra mile and do work you can be proud of.'
    },
    {
      icon: 'comment-alert',
      title: 'Take ownership and question the status quo in a constructive manner',
      description: 'Trust your gut and your knowledge, even if you need to question your team leader or CEO. We are all people – fallible but trying our best. Co.H’s culture allows us to appreciate openness and constructive feedback.'
    },
    {
      icon: 'boxing-glove',
      title: 'Be brave, curious and experiment – learn from all successes and failures',
      description: 'We believe that having the freedom to take risks and make mistakes is extremely important in any work environment. That’s how we learn, and how we innovate: by trying out ideas and looking at our results.'
    },
    {
      icon: 'chess-knight',
      title: 'Act in a way that makes all of us proud',
      description: 'We are all in this together and everything you say and do, whether internally or publically, reflects on us. We all deserve respect, and one way to make sure we get it is by holding ourselves accountable for our own actions.'
    },
    {
      icon: 'trophy',
      title: 'Build an inclusive, transparent and socially responsible culture',
      description: 'Culture doesn’t happen by chance – we need to be proactive and work on it every day, taking full responsibility for the results. We want everyone to have a voice, and we want our activities as a company to be transparent.'
    },
    {
      icon: 'chemical-weapon',
      title: 'Be ambitious and grow yourself and the people around you',
      description: 'Having ambition and working towards career goals is a wonderful thing, and we want to fully support you on your path. But as you forge ahead, be mindful of the people around you and of how you can help each other.'
    },
    {
      icon: 'dumbbell',
      title: 'Recognise excellence and engagement',
      description: 'What others think of you should never be your most important concern, but we all know the value of well-earned respect and constructive feedback. Validation and recognition are important.'
    },
  ],
  QUOTES: [
    {
      quote: 'Since the beginning our main goal was to help entrepreneurs build new software solution that matters. Building and shipping products that are helpful for people, making world a better place, for everyone, including ourselves.',
      avatar: 'steve.svg',
      name: 'Steve',
      position: 'Tech Master',
      background: 'background-50.jpg'
    },
    {
      quote: 'There is a group of talented people behind every product, and we feel proud having them all on board.',
      avatar: 'legolas.svg',
      name: 'Legolas',
      position: 'Senior Leader',
      background: 'background-49.jpg'
    }
  ],
  PROGRESS: [
    {
      title: 'Project Kickoff',
      description: 'Regardless of whether you need to craft an idea for a completely new product or evaluate the quality of an already existing one – we\'re here to help you.'
    },
    {
      title: 'Ideation and Evaluation',
      description: 'Give your product idea a real shape. Plan and evaluate the essential features of your product to accomplish your business goals and eliminate potential mistakes.'
    },
    {
      title: 'Design Process',
      description: 'Craft delightful user experiences for your digital products. Solve real problems and improve your vital business metrics through beautiful interfaces.'
    },
    {
      title: 'Development Process',
      description: 'Create beautiful, fast, and secure applications tailored precisely to your business goals.'
    }
  ],
  TEAM: [
    {
      avatar: 'quintus.svg',
      name: 'Quintus',
      position: 'Creative Director'
    },
    {
      avatar: 'steve.svg',
      name: 'Steve',
      position: 'Tech Master'
    },
    {
      avatar: 'legolas.svg',
      name: 'Legolas',
      position: 'Senior Leader'
    },
    {
      avatar: 'howard.svg',
      name: 'Howard',
      position: 'Tech Master'
    }
  ],
  ESTIMATION: {
    title: ['Our',
      <span className="text--secondary"> workplace</span>],
    description: 'Find us in Ho Chi Minh City',
    background: 'background-48.jpg',
  }
};

export const BLOGS_PAGE = {
  BANNER: {
    mainPage: 'blogs',
    img: 'background-14.jpg',
    title: ['Bespoke software development solutions', <span className="text--secondaryLight"> for your business</span>],
    description: 'Design. Development. Consultancy.'
  },
};

export const CAREERS_PAGE = {
  BANNER: {
    mainPage: 'careers',
    img: 'background-15.jpg',
    title: ['Bespoke software development solutions', <span className="text--secondaryLight"> for your business</span>],
    description: 'Design. Development. Consultancy.'
  },
};

export const ESTIMATION_PAGE = {
  BANNER: {
    mainPage: 'estimation',
    img: 'background-53.jpg',
    title: ['Get an ', <span className="text--secondaryLight">estimate</span>],
    description: 'Fill out this simple form. Our team will contact you promptly to discuss next steps.'
  },
};

//Service pages
export const ANDROID = {
  BANNER: {
    mainPage: 'services',
    img: 'background-18.jpg',
    title: ['Android development: Build ',
      <span className="text--secondaryLight">mobile apps </span>, 'with delightful UX'],
    description: 'Android developers at Co.H develop top quality mobile apps and help clients make the best decisions.'
  },
  FEATURES: [
    {
      icon: 'layers-triple',
      title: 'Design driven',
      description: 'We care for our apps to be beautiful, pixel perfect, fully interactive and user-friendly.',
    },
    {
      icon: 'account-star',
      title: 'Top talent',
      description: 'Technology by world class devs, recognised worldwide and held to highest quality standards.',
    },
    {
      icon: 'ab-testing',
      title: 'Testing process',
      description: 'Effective, battle-tested, reliable. Our testing process is designed to prepare your app for anything.',
    }
  ],
  PROJECT: {
    title: '3D Furniture: Software for the best experiences with 3D gallery view furniture',
    subTitle: 'Furniture Ecommerce with 3D gallery view.',
    description: 'Houzing is an e-commerce platform that connects customers with the most beautiful crafts. \n' +
      'Before contracting Co.H, Houzing was struggling with technical issues surrounding the project\'s online infrastructure.',
    url: '',
    image: '/projects/furniture.jpg'
  },
  ESTIMATION: {
    title: ['Build your product with ', <span className="text--secondary">Co.H</span>],
    description: 'Mobile developers at Co.H build native apps for iOS and Android devices, translating\n' +
      'your idea into apps your customers can enjoy.',
    background: 'background-34.jpg',
  }
};

export const BUSINESS_INTELLIGENCE = {
  BANNER: {
    mainPage: 'services',
    img: 'background-44.jpg',
    title: ['Business Intelligence: ',
      <span className="text--secondaryLight">Data-driven Solutions</span>, ' for Your Business'],
    description: 'Transforming data into actionable intelligence that informs an Organization’s strategic and tactical'
  },
  FEATURES: [
    {
      icon: 'database-settings',
      title: 'Manage Data Sources',
      description: '',
    },
    {
      icon: 'view-dashboard',
      title: 'Visualize',
      description: '',
    },
    {
      icon: 'home-analytics',
      title: 'Leverage Analystics',
      description: '',
    },
    {
      icon: 'arrow-decision',
      title: 'Make Data-Driven Decisions',
      description: '',
    }
  ],
  ESTIMATION: {
    title: ['Data-driven Solutions for Your Business with ', <span className="text--secondary">Co.H</span>],
    description: 'BI is almost certainly going to be a crucial part of most of the biggest success stories currently being written.\n' +
      'Write your success story with us.',
    background: 'background-46.jpg',
  }
};

export const PRODUCT_DESIGN__SPRINT = {
  BANNER: {
    mainPage: 'services',
    img: 'background-2.jpg',
    title: ['Product Design Sprint: ', <span className="text--secondaryLight">Launch a better product</span>],
    description: 'Design. Development. Consultancy.'
  },
  FEATURES: [
    {
      icon: 'group',
      title: 'Simple Solution',
      description: 'The collaborative approach and communication encourage getting a answers to complicated problems.',
    },
    {
      icon: 'account-search',
      title: 'User Understanding',
      description: 'The sprint is bases on is listening, trust and building meaningful relationships with users.',
    },
    {
      icon: 'chart-line-variant',
      title: 'Increased Efficiency',
      description: 'We have rock solid DevOps/SLA practices that we have developed to enhance trust, speed up development, resolve issues, and increase project security.',
    },
    {
      icon: 'shield-alert',
      title: 'Lower Risks',
      description: 'We offer development and design enhancements for your existing product or service. We can also keep your infrastructure up to date.',
    }
  ],
  PROCESS_MAP: [
    {
      icon: 'glasses',
      title: 'Understanding'
    },
    {
      icon: 'call-split',
      title: 'Diverge'
    },
    {
      icon: 'call-merge',
      title: 'Converge'
    },
    {
      icon: 'arrange',
      title: 'Prototype'
    },
    {
      icon: 'debug-step-into',
      title: 'Test'
    }
  ],
  ESTIMATION: {
    title: ['Get answers to critical business questions fast with ',
      <span className="text--secondary">Product Design Sprint</span>],
    description: 'Let us know about your idea or a problem and we’ll help you find the right solutions.',
    background: 'background-23.jpg',
  }
};

export const QUALITY_ASSURANCE = {
  BANNER: {
    mainPage: 'services',
    img: 'background-1.jpg',
    title: ['Quality Assurance: ', <span className="text--secondaryLight">Securing your product's success</span>],
    description: 'Quality Assurance Specialists are the quiet guardians of your project. It\'s their job to make sure ' +
      'your product goes through the development without any major issues and delivers a flawless experience to users ' +
      'once released.'
  },
  FEATURES: [
    {
      icon: 'feature-search',
      title: 'Assessing the Requirements',
      description: 'Even before actual programming begin, QA Specialists can identify gaps in the speicification or other issues which, if overlooked, could make a negative impact on the pace of development.',
    },
    {
      icon: 'code-tags-check',
      title: 'Testing even the smallest bits of code',
      description: 'Event bit of code is tested on seperate integration branches. This allows for functional testing of new features in an isolated environment, without the risk of harming your application.',
    },
    {
      icon: 'checkbox-multiple',
      title: 'Analysing complete features',
      description: 'QA Specialists look at your product critically and product valuable improvement suggestions. Whether it is a functionality or the look of the product, we want it all to be close to the perfection.',
    },
    {
      icon: 'balloon',
      title: 'Maintaining the quality of your product',
      description: 'The work never stops. To avoid regressions or unexpected bugs, QA Specialists write automated tests, conduct exploratory tests and organise bug bath events. There is always something that can be improved.',
    }
  ],
  ESTIMATION: {
    title: ['Make sure your product is of the highest quality'],
    description: 'Co.H\'s Quality Assurance Specialists work with any technology and offer assistance in all kinds of\n' +
      'projects. We can help you, too.',
    background: 'background-37.jpg',
  }
};

export const PROGRESSIVE_WEB_APPS = {
  BANNER: {
    mainPage: 'services',
    img: 'background-5.jpg',
    title: [<span className="text--secondaryLight">Progressive Web Applications</span>],
    description: 'Progressive Web Apps: Build mobile-first web apps with delightful UX'
  },
  FEATURES: [
    {
      icon: 'fast-forward',
      title: 'Fast',
      description: 'A focus on speed lowers bounce rates and increases the number of monthly active users. Give your users the best possible experience in the modern web, cheaper than through a native mobile app.',
    },
    {
      icon: 'lightbulb',
      title: 'Reliable',
      description: 'Progressive Web Applications work in offline mode, with poor connection, on all browsers and all devices. Target all your possible users with one platform and deliver a high quality experience to every one of them.',
    },
    {
      icon: 'transit-connection-variant',
      title: 'Engaging',
      description: 'Increase conversion by focusing on native features of modern web that you might not even be aware of. Take advantage of push notifications, homescreen shortcuts and offline storage.',
    }
  ],
  ESTIMATION: {
    title: ['Build your product with ', <span className="text--secondary">Co.H</span>],
    description: 'Web developers at Co.H build performance applications your customers can enjoy. ' +
      'They translate your ideas into functional products in a fast and flexible manner.',
    background: 'background-31.jpg',
  }
};

export const DEVOPS = {
  BANNER: {
    mainPage: 'services',
    img: 'background-38.jpg',
    title: ['Devops: Build a ',
      <span className="text--secondaryLight">stable and secure architecture</span>, ' for your app'],
    description: 'Devops is a set of practices developed to enhance trust, speed up development, resolve issues and increase project security.'
  },
  FEATURES: [
    {
      icon: 'radar',
      title: 'Predictable',
      description: 'Devops best practices guarantee that the software we deliver is stable and works correctly.',
    },
    {
      icon: 'arrow-expand',
      title: 'Scalable',
      description: 'We use only reliable cloud solutions that enable us to scale apps depending on our clients’s needs.',
    },
    {
      icon: 'shield-key',
      title: 'Secure',
      description: 'We rely only on proven and safe solutions so that your users’ data in the app is always secure.',
    },
    {
      icon: 'scale-balance',
      title: 'Stable',
      description: 'By employing the Agile methodology we ensure that all operating environments are 100% stable.',
    }
  ],
  ESTIMATION: {
    title: ['Make your product secure with ', <span className="text--secondary">Co.H</span>],
    description: 'Devops experts at Co.H make your applications secure and reliable. ' +
      'They can build a complete architecture for your product in a fast and flexible manner.',
    background: 'background-39.jpg',
  }
};

export const SCOPING_SESSION = {
  BANNER: {
    mainPage: 'services',
    img: 'background-15.jpg',
    title: ['Scoping session: Transform your vision into ',
      <span className="text--secondaryLight">a business reality</span>],
    description: 'Scoping sessions are the best way to shape your business ideas. Together we will turn your concept into a product that inspires masses.'
  },
  FEATURES: [
    {
      icon: 'drawing',
      title: 'Concept Definition',
      description: 'Scoping sessions ebable you to evaluate your business vision with our soecialists. We will help you review your idea and choose the most suitable technical solutions. We will provide you with insights and valuable expert feedbacks.',
    },
    {
      icon: 'comment-account',
      title: 'User Stories Creation',
      description: 'Before crafting your action plan, we will help you create user stories for your product. We will go through your enhenced business concept and develop accurate user personas.',
    },
    {
      icon: 'trello',
      title: 'Estimation',
      description: 'Our scoping session services will enable us to create realistic estomation of the costs and timeframes of your project. Our industries expertise ensures that you will get the most accurate values.',
    },
    {
      icon: 'map-marker',
      title: 'Roadmap Creation',
      description: 'The scoping session ends with the prepairation of a customised roadmap document. We will create concrete process guidlines for the development of your product idea. We will provide you with our best expert insights.',
    }
  ],
  ESTIMATION: {
    title: ['Build your product with ', <span className="text--secondary">Co.H</span>],
    description: 'We have years of experience building successful web applications.',
    background: 'background-24.jpg',
  }
};

export const IOS_DATA = {
  BANNER: {
    mainPage: 'services',
    img: 'background-32.jpg',
    title: ['iOS development: Build ', <span className="text--secondaryLight">mobile apps</span>, 'with delightful UX'],
    description: 'iOS developers build mobile native applications using the latest technology standards.'
  },
  PROJECT: {
    title: 'Build an high performance iOS product',
    subTitle: 'Online dating services, matching perfect partner',
    description: 'Berrys asked Co.H to create an online service for searching \n' +
      'for crushes and interacting between contacts.',
    path: '',
    image: '/projects/berrys.jpg'
  },
  FEATURES: [
    {
      icon: 'lightbulb',
      title: 'Reliable',
      description: 'We support entrepreneurs across the globe. We work with startups and well‑established enterprises and help them grow their businesses. We understand their primary goal well – get a working product to the market fast.',
    },
    {
      icon: 'account-star',
      title: 'Talented',
      description: 'Only 4% of people who apply to us each year end up as our full time employees. Each new addition to our team is vetted by senior members, has to pass a language test and a pair coding audit.',
    },
    {
      icon: 'radar',
      title: 'Predictable',
      description: 'Our unique approach to software development is based on almost ten years of experience. We use machine learning to predict the outcome of each development sprint. We can accurately predict the outcomes on highly unpredictable markets.',
    }
  ],
  ESTIMATION: {
    title: ['Build your product with ', <span className="text--secondary">Co.H</span>],
    description: 'Product Designers at Co.H have experience in launching products successfully.\n' +
      'They can understand business problems and find efficient solutions.',
    background: 'background-33.jpg',
  }
};

export const MACHINE_LEARNING = {
  BANNER: {
    mainPage: 'services',
    img: 'background-3.jpg',
    title: ['Machine Learning: Make data ', <span className="text--secondaryLight">work for you</span>],
    description: 'Machine Learning and Artificial Intelligence will turn data into a competitive advantage'
  },
  FEATURES: [
    {
      icon: 'chart',
      title: 'Identifying Trends and Patterns',
      description: 'Machine Learning can be used to provide relevant product or content recommendations, making user experience highly personalized.',
    },
    {
      icon: 'chevron-triple-up',
      title: 'Continuous Improvement',
      description: 'Machine Learning improves over time as more data is processed and more patterns are discovered, which results in better decisions and predictions.',
    },
    {
      icon: 'filter-variant',
      title: 'Adaptation and Filtering',
      description: 'Machine Learning allows applications to adapt to new situations amd implement filters in response to new trends that are identified.',
    },
    {
      icon: 'shield-key',
      title: 'Security',
      description: 'Machine Learning facilitates detecting suspicious activity within an app, such as fraudulent credit card transactions, or email and forum spammers.',
    }
  ],
  ESTIMATION: {
    title: ['Build your ML solution with ', <span className="text--secondary">Co.H</span>],
    description: 'Machine Learning engineers at Co.H build AI-powered solutions that will help you automate processes and workflow.\n' +
      'They translate your ideas into functional products in a quick and flexible manner.',
    background: 'background-40.jpg',
  }
};

export const UX_REVIEW = {
  BANNER: {
    mainPage: 'services',
    img: 'background-21.jpg',
    title: ['UX Review: Increase ', <span className="text--secondaryLight">business value</span>, ' of your product'],
    description: 'UX Review will help you eliminate all UX issues in your app and get a truly appealing and streamlined product'
  },
  SUB_MENU: [
    {
      id: 'process',
      name: 'Process',
    },
    {
      id: 'industries',
      name: 'Industries',
    },
    {
      id: 'testimonials',
      name: 'Testimonials',
    },
  ],
  FEATURES: [
    {
      icon: 'magnify',
      title: 'Initial Research',
      description: 'What is the problem you’d like to solve? We analyse the product, user and market data.',
    },
    {
      icon: 'poll',
      title: 'Review & Analysis',
      description: 'We leverage scientifically-backed methods to spot issues in usability and user experiences.',
    },
    {
      icon: 'file-chart',
      title: 'Report Creation',
      description: 'We give you solutions to the issues we find. You will get a quick wins, recommendations, and estimations for improvements.',
    },
    {
      icon: 'presentation',
      title: 'Presentation',
      description: 'We talk outcomes and priorities and recommend the next steps you should take.',
    }
  ],
  PROJECT: {
    title: 'Ideation workshop for the world\'s largest automotive brand',
    subTitle: 'A 3-day workshop to design the first-of-its-kind project for Volkswagen',
    description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service\n' +
      'process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
    path: '',
    image: '/projects/videostreamshopping.jpg'
  },
  ESTIMATION: {
    title: ['Build your product with ', <span className="text--secondary">Co.H</span>],
    description: 'Product designers at Co.H design native apps for iOS and Android devices, translating your idea into apps\n' +
      'your customers can enjoy.',
    background: 'background-25.jpg',
  }
};

export const DIGITAL_TRANSFORMATION = {
  BANNER: {
    mainPage: 'services',
    img: 'background-41.jpg',
    title: [<span className="text--secondaryLight">Digital Transformation Services</span>],
    description: 'Digital transformation can help increase revenues, cut costs, improve security, and build new ways of\n' +
      'organizing processes. It creates new customer experiences and drives more value to employees.'
  },
  FEATURES: [
    {
      icon: 'shield-key',
      title: 'High Security',
      description: 'The likelihood of cyder attacks on data has dramatically increased in recent years. Digital transformation will help you keep data secure.',
    },
    {
      icon: 'chart-line-variant',
      title: 'Improved Processes',
      description: 'Digital transform in practice: better control and transparency of business finances, improved customer services, and optimized workflow.',
    },
    {
      icon: 'piggy-bank',
      title: 'Lower costs',
      description: 'Digital change can help your company to reduce costs up to 90%. It’s one of the main elements of proper digital transformation strategy.',
    },
    {
      icon: 'finance',
      title: 'Increased Revenues',
      description: 'A recent study has shown that a digital-first strategy may increase revenue by over 30%. Digital transformation is a chance to earn more.',
    }
  ],
  PROJECT: {
    title: 'Ideation workshop for the world\'s largest automotive brand',
    subTitle: 'A 3-day workshop to design the first-of-its-kind project for Volkswagen',
    description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service\n' +
      'process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
    path: '',
    image: 'background-9.jpg'
  },
  ESTIMATION: {
    title: ['Start your Digital Transformation with ', <span className="text--secondary">Co.H</span>],
    description: 'Our experience in digital transformation services can help your business to grow fast and mature\n' +
      'digitally. We can assist you to take the right steps towards digital transformation.',
    background: 'background-42.jpg',
  }
};

export const PRODUCT_DESIGN = {
  BANNER: {
    mainPage: 'services',
    img: 'background-14.jpg',
    title: ['Product design. ', <span className="text--secondaryLight">Craft delightful experiences</span>],
    description: 'Solve real human problems and provide the ultimate UX for your web and mobile applications.'
  },
  FEATURES: [
    {
      icon: 'eye-circle',
      title: 'UX Review',
      description: 'Report anaysis of the product’s market fit, usbalibity, and user experience.',
    },
    {
      icon: 'bulletin-board',
      title: 'Product Design Sprint',
      description: 'A creative workshop that will help you answer crucial business questions.',
    },
    {
      icon: 'vector-square',
      title: 'UX Design',
      description: 'Solving real human problems and providing the ultimate UX for your web and mobile applications.',
    },
    {
      icon: 'map-marker',
      title: 'Roadmap Creation',
      description: 'Ideation and product creation product at every stage of the product’s lifecycle.',
    }
  ],
  PROJECT: {
    title: 'Learning how people react with 3D modeling selection with new Furniture Ecommerce App',
    subTitle: 'Houzing - Furniture Ecommerce with 3D gallery view.',
    description: 'Houzing is an e-commerce platform that connects customers with the most beautiful crafts. \n' +
      'Before contracting Co.H, Houzing was struggling with technical issues surrounding the project\'s online infrastructure.',
    path: '',
    image: '/projects/furniture.jpg'
  },
  ESTIMATION: {
    title: ['Design your product with ', <span className="text--secondary">Co.H</span>],
    description: 'Product designers at Co.H design engaging web and mobile applications your customers can enjoy. The ' +
      'translate your ideas into functional products in a fast and flexible manner.',
    background: 'background-26.jpg',
  }
};

export const REACTJS = {
  BANNER: {
    mainPage: 'services',
    img: 'background-17.jpg',
    title: ['React: Build apps with ', <span className="text--secondaryLight">delightful UX</span>],
    description: 'React.js is a javascript library for crafting responsive and engaging user interfaces.'
  },
  FEATURES: [
    {
      icon: 'finance',
      title: 'Performance',
      description: 'React users a powerful reconciliation algorithm ( sometimes call virtual DOM ), which updates the UI very fluidly. React Native apps are responsive and engaging.',
    },
    {
      icon: 'scale-balance',
      title: 'Stable',
      description: 'React users sematic versioning and doesn’t change the public API. You can stay up-to-date with the latest stable version without rewrite your codebase.',
    },
    {
      icon: 'cube-scan',
      title: 'Modern',
      description: 'It allows you to embrace the technology of tomorrow today and build Progessive Web App with React - just like Twitter and many other tech companies.',
    },
    {
      icon: 'chart-line-variant',
      title: 'Efficient',
      description: 'React has many tested libraries to serve common user cases. Don’t reinvent the wheel - your React developers can focus purely on implementing the business logics.',
    }
  ],
  PROJECT: {
    title: 'React - Co.H Process',
    subTitle: 'Movie Landing Page, Event Premier Registration',
    description: 'Create a digital platform for fiml event premier with an innovative business model, \n' +
      'where users can hunt complete first tickets of the big screen movies and the modern way for movie production team prmote their own movie.',
    path: '',
    image: '/projects/movie.jpg'
  },
  ESTIMATION: {
    title: ['Build your product in React with ', <span className="text--secondary">Co.H</span>],
    description: 'React developers at Co.H build beautiful interfaces for applications your customers can enjoy. ' +
      'They translate your ideas into functional products in a fast and flexible manner.',
    background: 'background-30.jpg',
  }
};

export const REACT_NATIVE = {
  BANNER: {
    mainPage: 'services',
    img: 'background-7.jpg',
    title: ['React Native Development: ', <span className="text--secondaryLight">apps</span>, ' with delightful UX'],
    description: 'React Native is a powerful framework for cross-platform development. One technology, one codebase,\n' +
      'multiple devices, faster development.'
  },
  FEATURES: [
    {
      icon: 'fast-forward',
      title: 'Fast',
      description: 'We support entrepreneurs across the globe. We work with startups and well‑established enterprises and help them grow their businesses. We understand their goals - to get the working product to the market fast.',
    },
    {
      icon: 'account-star',
      title: 'Talented',
      description: 'Only 4% of people who apply to us each year become full-time employees. Each new member is vetted by senior members, has to pass a language test and a pair coding audit.',
    },
    {
      icon: 'radar',
      title: 'Predictable',
      description: 'Our unique approach to software development is based on almost ten years of experience. We use machine learning to predict the outcome of each development sprit. Thus, we can accurately predict the results on a highly unpredictable market.',
    }
  ],
  PROJECT: {
    title: 'Ideation workshop for the latest printing online solution',
    subTitle: 'Online printing services, and marketplace for baby and mom stuffs',
    description: 'Mr.Quoc came up with an idea for everyone who want to save their memories everytime and everywhere,take their photos alive and correct their home, \n' +
      'through mobile and web application.He need a team to help he develop the concept and product.',
    path: '',
    image: '/projects/molli.jpg'
  },
  ESTIMATION: {
    title: ['Build your product in React Native with ', <span className="text--secondary">Co.H</span>],
    description: 'Mobile developers at Co.H build native apps for iOS and Android devices, translating\n' +
      'your idea into apps your customers can enjoy.',
    background: 'background-36.jpg',
  }
};

export const UX_SERVICES = {
  BANNER: {
    mainPage: 'services',
    img: 'background-13.jpg',
    title: ['UX Services -  ',
      <span className="text--secondaryLight">Humanise your software</span>, ' user experience'],
    description: 'Solve real human problems thanks to the knowledgeable UX design agency that will provide the ' +
      'ultimate UX for your web and mobile applications.'
  },
  SUB_MENU: [
    {
      id: 'overview',
      name: 'Overview'
    },
    {
      id: 'benefits',
      name: 'Benefits'
    },
    {
      id: 'process',
      name: 'Process'
    },
    {
      id: 'uxServices',
      name: 'UX Services'
    },
  ],
  FEATURES: [
    {
      icon: 'glasses',
      title: 'Manage Data Sources',
      description: 'Analyse your UX and check your market fit to know what improvements you should introduce. UX servives will help you obtains valuable insights.',
    },
    {
      icon: 'debug-step-into',
      title: 'Act',
      description: 'Implement knowledge-base change to enhene user experience and conversion. Transform data into decisions that will help you achive business goals.',
    },
    {
      icon: 'poll',
      title: 'Measure',
      description: 'Watch out the implemented solution makes the numbers skyrocket. Our well-established practices and proven methods will help you grow your ROI.',
    }
  ],
  FEATURES_2: [
    {
      icon: 'fast-forward',
      title: 'Fast',
      description: 'We support entrepreneurs across the globe. We work with startups and well‑established enterprises and help them grow their businesses. We understand their goals - to get the working product to the market fast.',
    },
    {
      icon: 'account-star',
      title: 'Talented',
      description: 'Only 4% of people who apply to us each year become full-time employees. Each new member is vetted by senior members, has to pass a language test and a pair coding audit.',
    },
    {
      icon: 'radar',
      title: 'Predictable',
      description: 'Our unique approach to software development is based on almost ten years of experience. We use machine learning to predict the outcome of each development sprit. Thus, we can accurately predict the results on a highly unpredictable market.',
    }
  ],
  PROJECT: {
    title: 'Growing key tech partnership with best cooking class system',
    subTitle: 'MoM cooking class - Cuisine Cooking Class for foreigners, tourisms and market place for menu recipies',
    description: 'Co.H helped MoM expand its tech services, assembled and introduced a team to work on a platform for handling onine booking and payment. \n' +
      'The platform is now powering with other econmmerces and events paltform. Over a year, MoM has grew to a 50-person strong team.',
    path: '',
    image: '/projects/mom.jpg'
  },
  ESTIMATION: {
    title: ['Boost your product with ', <span className="text--secondary">Co.H</span>],
    description: 'UX Designers at Co.H have experience in solving UX problems and launching products successfully.\n' +
      'They understand business problems, and they know how to find efficient solutions.',
    background: 'background-27.jpg',
  }
};

export const NODE_JS = {
  BANNER: {
    mainPage: 'services',
    img: 'background-16.jpg',
    title: ['Node.js: ', <span className="text--secondaryLight">Build performance web apps</span>],
    description: 'Node.js enables you to build performance web applications based on Javascript – one language for\n' +
      'full-stack development.'
  },
  FEATURES: [
    {
      icon: 'fast-forward',
      title: 'Fast',
      description: 'Node.js’s event-driven I/O model supports the handling of concurrent request. As a  result, the app is fast even when performing multiple operations at the same time.',
    },
    {
      icon: 'arrow-expand',
      title: 'Scalable',
      description: 'The event-based model also enhences the scalabilty and performance of Node.js applications. Node.js usually scales a better than most other typical frameworks.',
    },
    {
      icon: 'feather',
      title: 'Lightweight',
      description: 'The non-blocking and event-driven I/O makes apps lightweight and effient in the face of data-intensive real-time tasks running accross distributed devices.',
    },
    {
      icon: 'scissors-cutting',
      title: 'Tailored',
      description: 'Node.js give you more freedom to build the app your own way. Developers are not restrained by excessive rules and guidlines. Your app will be exactly as you want.  ',
    }
  ],
  PROJECT: {
    title: 'Development Building Rental for Professional Agency',
    subTitle: 'Huy Minh Realestate - Building Rental Agency',
    description: 'Rental building of Mss.Hà was been using in the traditional way with MS.Excel and MS.Words. She need to innovate up the business model and scale it up. \n' +
      'She required a system that could help automate rental request from customer and build up contracts. And other department could use it to tracking and communicate.',
    path: '',
    image: '/projects/huyminh.jpg'
  },
  ESTIMATION: {
    title: ['Build your product in Node.js with ', <span className="text--secondary">Co.H</span>],
    description: 'Node.js developers at Co.H build reliable and fast applications your customers will enjoy.They translate\n' +
      'your ideas into functional products in a quick and flexible manner.',
    background: 'background-29.jpg',
  }
};

export const FLUTTER = {
  BANNER: {
    mainPage: 'services',
    img: 'background-6.jpg',
    title: ['Flutter Development: Fast way to ', <span className="text--secondaryLight">market your app</span>],
    description: 'Flutter is an easy way to build and deploy apps both for iOS and Android with a single code base.'
  },
  FEATURES: [
    {
      icon: 'layers-triple',
      title: 'Layered Architecture',
      description: 'Each layer of the Fluter architecture is built upon the previous one, which helps you to design more with less code.',
    },
    {
      icon: 'atom-variant',
      title: 'Native Feel',
      description: 'Widgets incorporate all critical platform differences like fonts, navigation, scrollings, icons, to provide a natural feel.',
    },
    {
      icon: 'fast-forward',
      title: 'Superfast Performance',
      description: 'Dart’s Ahead-of-Time compilation and lack of a JavaScript bridge improve app startup time and its performance.',
    },
    {
      icon: 'poll',
      title: 'UX Perfection',
      description: 'Compilation to native code ( the Dart engine draws elements from scratch on the screen ) allows greater control over each pixel.',
    }
  ],
  FEATURES_2: [
    {
      icon: '',
      title: 'Fast development',
      description: 'You get a universal set of UI building blocks you may assemble using a clear layered architecture. Additionally hot reload allows you to experiment freely, add features, and remove bugs in no time, which is perfect for your MVP.',
    },
    {
      icon: '',
      title: 'Flexible UI',
      description: 'The widgets are adaptable and make your UI flexible, so you can reuse the modules and whole sets of components in different layout configurations. Flutter also brings to the table an ability for real-time UI iteration, which enables flexible customization.',
    },
    {
      icon: '',
      title: 'Excellent performance',
      description: 'Thanks to its architecture and engineering design, Flutter aims to provide 60 or 120 frames per second (fps) performance on devices capable of 120Hz screen refresh rates. It let you build very responsive and user-friendly apps.',
    },
    {
      icon: '',
      title: 'Hot Reload',
      description: 'With Hot Reload you can see every change you make immediately in the app. Every feature or fix is applied there within seconds. It makes a big difference for software developers as immediate feedback makes coding much easier, faster, and more precise. ',
    },
    {
      icon: '',
      title: 'Less code',
      description: 'While React Native allows you to share over 90% of the code between the Android and iOS apps, in case of Flutter you can practically reuse 100% of the code. This speeds up the development process and makes your product less susceptible to mistakes.',
    },
    {
      icon: '',
      title: 'Support for native modules',
      description: 'Flutter can interact with cameras, network, geolocation, and storage flawlessly. The support for native modules was one of the biggest barriers for hybrid mobile development and Google\'s Flutter may have found a way to break through.',
    }
  ],
  PROJECT: {
    title: 'Challenging to transformation Ecommerce into Social Livestream Network',
    subTitle: 'Video Streaming integrated with Ecommerce, Make Shopping more emgagement and fun',
    description: 'Trang told us wanted to create a fashion application that would boost women\'sconfidence with luxury collections as well as raise societal and environmental awareness.\n' +
      'That’s how the idea for the e-commerce platform with stylish clothes integrated with Live Stream platform.',
    path: '',
    image: '/projects/videostreamshopping.jpg'
  },
  ESTIMATION: {
    title: ['Make your idea come true with ', <span className="text--secondary">Co.H</span>],
    description: 'Flutter is an opportunity to build beautiful apps with excellent performance and smooth animations.\n' +
      'Using this framework you will reach both Android and iOS users at once.',
    background: 'background-35.jpg',
  }
};

export const ILLUSTRATIONS = {
  BANNER: {
    mainPage: 'services',
    img: 'background-19.jpg',
    title: ['Illustrations: a ',
      <span className="text--secondaryLight">progressive look</span>, ' at shapes and colours'],
    description: 'An illustration is not only an adornment that makes your product beautiful. If it’s created with careful\n' +
      'consideration, it can significantly impact your revenue.'
  },
  FEATURES: [
    {
      icon: 'antenna',
      title: 'Impact',
      description: 'Recognition and brand affection among new and recurring users.',
    },
    {
      icon: 'arm-flex',
      title: 'Personality',
      description: 'A unique look of the brand makes it outstanding and appealing.',
    },
    {
      icon: 'anchor',
      title: 'Visual Anchor',
      description: 'Well-dong illustrations draw attention and convey a message.',
    },
    {
      icon: 'details',
      title: 'Refine Artwork',
      description: 'Emphasise professionalism and attention to details.',
    }
  ],
  PROCESS_MAP: [
    {
      icon: 'feature-search',
      title: 'Research'
    },
    {
      icon: 'draw',
      title: 'Sketch'
    },
    {
      icon: 'crystal-ball',
      title: 'Refinement'
    },
    {
      icon: 'donkey',
      title: 'Artwork'
    }
  ],
  SHOWCASE: [
    {
      title: 'Title',
      description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service ' +
        'process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
      image: 'background-17.jpg'
    },
    {
      title: 'Title',
      description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service ' +
        'process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
      image: 'background-17.jpg'
    },
    {
      title: 'Title',
      description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service ' +
        'process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
      image: 'background-17.jpg'
    },
    {
      title: 'Title',
      description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service ' +
        'process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
      image: 'background-17.jpg'
    }
  ],
  ESTIMATION: {
    title: ['Design your product with ', <span className="text--secondary">Co.H</span>],
    description: 'Product designers at Co.H design engaging web and mobile applications your customers can enjoy. ' +
      'They translate your ideas into functional products in a fast and flexible manner.',
    background: 'background-28.jpg',
  }
};

export const DATA_ANNOTATION = {
  BANNER: {
    mainPage: 'services',
    img: 'background-43.jpg',
    title: ['Data annotation services ', <span className="text--secondaryLight">made easier</span>],
    description: 'Annotate data quickly and effectively through the community of professionals on our platform'
  },
  FEATURES: [
    {
      icon: 'fast-forward',
      title: 'Gain access to professional annotators',
      description: 'Our platform is a community hub for data annotation professionals worldwide.',
    },
    {
      icon: 'account-star',
      title: 'Annotate various types of data',
      description: 'We support text, images, sound, and video, using the most popular techniques.',
    },
    {
      icon: 'radar',
      title: 'Take advantage of built-in tools',
      description: 'Easily divide data into sets and send them to annotators without compromising security.',
    }
  ],
  FEATURES_2: [
    {
      icon: 'fast-forward',
      title: 'Collect your data',
      description: 'Choose the type of data that suits your purposes, and collect as much as you need.',
    },
    {
      icon: 'account-star',
      title: 'Define classes',
      description: 'Define the categories for the classification of your data. In the case of Carlens, we used car models.',
    },
    {
      icon: 'radar',
      title: 'Describe each class',
      description: 'Write up the characteristics for each class and create a rule book. Your annotators will need it when they run into less obvious cases.',
    },
    {
      icon: 'radar',
      title: 'Begin annotation in DAP',
      description: 'Get to work! If you have a lot of data, you’ll need a team of annotators. Manual data annotation takes time.',
    }
  ],
  FEATURES_3: [
    {
      icon: 'imgage',
      title: 'Image',
      description: 'We offer binary and multiple choice photo sets, giving you extra flexibility.',
    },
    {
      icon: 'video',
      title: 'Video',
      description: 'Labeling video data can be a challenge. We want to make it easy for your annotators.',
    },
    {
      icon: 'sound',
      title: 'Sound',
      description: 'Annotate sound files based on type, author, the genre of music - and much more',
    },
    {
      icon: 'format-text',
      title: 'Text',
      description: 'Text data can be the basis for language learning projects, but it’s not limited to that use.',
    }
  ],
  FEATURES_4: [
    {
      icon: 'fast-forward',
      title: 'Annotate your data',
      description: 'Data labelling and classification doesn’t need to be a chore. Our platform is user-friendly and easy to learn.',
    },
    {
      icon: 'account-star',
      title: 'Build your learning model',
      description: 'Use high quality annotated data when building your machine learning algorithms.',
    },
    {
      icon: 'radar',
      title: 'Reach for the sky with your ML project',
      description: 'Take your business to the next level with machine learning. This is only the beginning.',
    }
  ],
  PROJECT: {
    title: 'Ideation workshop for the world\'s largest automotive brand',
    subTitle: 'A 3-day workshop to design the first-of-its-kind project for Volkswagen',
    description: 'Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service\n' +
      'process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.',
    path: '',
    image: 'background-12.jpg'
  },
  ESTIMATION: {
    title: ['Boost your machine learning project with high quality annotated data'],
    description: 'If you love deep learning algorithms and cutting-edge technology, you’ll love this!',
    background: 'background-45.jpg',
  }
};
