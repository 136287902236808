import React from 'react';
import Avatar from "../Avatar";

const QuoteServiceComponent = ({data}) => {
  return (
    <div className="quote__service bg--default">
      <div className="max__width quote__service__inner">
        <h2>{data.title}</h2>
        <p>{data.description}</p>
        <div className="card__list">
          {
            data.listing.map((item, index) => (
              <div className="card" key={index}>
                <div className="card__header">
                  <img src={`/images/logo/${item.logo}`} alt="Logo"/>
                </div>
                <p>{item.description}</p>
                <div className="card__footer">
                  <Avatar avatar={item.member.avatar} size={40} />
                  <div className="m__l--10">
                    <b>{item.member.fullName}</b>
                    <p>{item.member.position}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
};

export default QuoteServiceComponent;
