import React, { PureComponent } from 'react';

import { REACT_NATIVE } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import ProjectComponent from "../../components/Project";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class ReactNative extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={REACT_NATIVE.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Mobile cross-platform development</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Cross-platform mobile development is a great alternative to native iOS and Android mobile apps. React
                Native is one of the best options for mobile development. It allows you to cut expenses and save time –
                you can build both iOS and Android apps simultaneously. The React Native framework is trending, and is
                now considered as the top choice for cross-platform mobile app development.
              </p>
              <p className="text--gray m__b--10">
                React Native is a JavaScript framework for building native mobile applications. It was developed by
                Facebook and gained a lot of popularity. It’s about 30% faster to build a React Native mobile app
                supporting both iOS and Android than to develop native apps separately.
              </p>
              <p className="text--gray m__b--10">
                Our experienced frontend team can deliver state-of-the-art progressive web applications that embrace the
                newest available browser APIs, providing native experience available on every device of your users.
                Thanks to the progressive web apps concept you can significantly improve your conversion rates, bounce
                rates, and engagement. Read more about how it’s possible.
              </p>
              <p className="text--gray m__b--10">
                Co.H has a diverse team of engineers, designers and project managers. Our team can handle the complete
                development of a React Native application, including UI/UX, backend development and everything else you
                need to launch your mobile app. We are leaders in new technologies for building cross-platform mobile
                apps. With several React Native iOS and Android apps in the works, our team has reached another level of
                cross-platform app development efficiency and speed.
              </p>
            </div>
            <ProjectComponent data={REACT_NATIVE.PROJECT}/>
          </section>

          <section className="section">
            <h1 className="border border--center">Really, advantage is on our side</h1>
            <FeaturesComponent data={REACT_NATIVE.FEATURES}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={REACT_NATIVE.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default ReactNative;
