import React, { PureComponent } from 'react';

import { ILLUSTRATIONS } from "../../constants";
import Icon from "../../components/Icon";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class Illustrations extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={ILLUSTRATIONS.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Illustration at Co.H</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Eighty percent of the world’s population are visualisers. This fact tells us the cruel but fair truth:
                our clients learn, remember, and, very often, make buying decisions by looking at something, in the heat
                of the moment.
              </p>
              <p className="text--gray m__b--10">
                Your product needs a strong, visual reason to draw your client’s attention, make them focused, and
                explain to them complex information quickly.
              </p>
              <p className="text--gray m__b--10">
                If the illustrations are drawn with purpose and consideration, they can lay the foundation for a
                long-term relationship between you and your customers, which will definitely have impact on your market
                position.
              </p>
            </div>

            <FeaturesComponent data={ILLUSTRATIONS.FEATURES}/>
          </section>

          <section className="section">
            <h1 className="border border--center">An efficient process is key</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                We’re driven by our clients’ goals, which is why we built our own approach. When working on
                illustrations, we use a three-step process that consists of research & brainstorming, hand drawn
                sketching, and preparing illustrations in cutting-edge software.
              </p>
            </div>
            <div className="process__map">
              {
                ILLUSTRATIONS.PROCESS_MAP.map((item, index) => (
                  <div className="process__map__item" key={index}>
                    <div className="process__map__icon">
                      <Icon name={item.icon} className="icon--secondary border--around"/>
                      <span>{index + 1}</span>
                    </div>
                    <b>{item.title}</b>
                  </div>
                ))
              }
            </div>
            <div className="special__box">
              <div className="box__left">
                <h3 className="border border--left">Benefits</h3>
                <p>
                  Our clients often cooperate with us for many years, launching various products. It’s because we always
                  strive to become trusted technological partners who care to provide more than just software
                  development expertise. Thanks to that, we work as an extension of our clients’ teams rather than an
                  outsourcing agency.
                </p>
              </div>
              <div className="box__right">
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Our brains love stories. Static or moving images are great storytelling tools. They can evoke strong
                    emotions and make your product unforgettable.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Custom-built illustrations help establish a unique and unmistakable visual language of your brand or
                    product.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Illustrations can be used consistently on all media such as web, print, social etc. Consistency in
                    branding is always rewarded with the loyalty of customers.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Illustrations are great as simplifying ideas. They are an emotional medium, making the message they
                    carry warmer, less stress-including, and easier to digest.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <h1 className="border border--center">Showcase</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Thanks to the diversity of our illustrators' skills, we can create images in pretty much every style,
                using different types and techniques. Below, you'll find some examples that represent our passion and
                experience.
              </p>
            </div>
            <div className="showcase">
              {
                ILLUSTRATIONS.SHOWCASE.map((item, index) => (
                  <div className={`showcase__item ${index % 2 !== 0 ? 'showcase__item--reverse' : ''}`}>
                    <div className="showcase__img" style={{backgroundImage: `url(/images/${item.image})`}}/>
                    <div className="showcase__content">
                      <h4 className="border border-left">{item.title}</h4>
                      <p>Volkswagen asked Netguru to run a Product Design Sprint workshop to design a customer service
                        process for Volkswagen Home - a first-in-the-world boutique for the German automotive company.</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={ILLUSTRATIONS.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default Illustrations;
