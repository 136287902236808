import React, { PureComponent } from 'react';

import { BLOGS_PAGE } from "../../constants";

import BannerComponent from "../../components/Banner";

class BlogsPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--blogs">
        <BannerComponent data={BLOGS_PAGE.BANNER} />
        <div className="page__content bg--white" />
      </div>
    );
  }
}

export default BlogsPage;
