import React, { PureComponent } from 'react';
import { Parallax } from 'react-parallax';

import { SERVICES_PAGE } from '../../constants';

import SubMenuComponent from "../../components/SubMenu";
import BannerComponent from "../../components/Banner";
import EstimationComponent from "../../components/Estimation";
import ProjectComponent from "../../components/Project";
import FeaturesComponent from "../../components/Features";
import QuoteServiceComponent from "../../components/QuoteService";

class ServicesPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--services">
        <BannerComponent data={SERVICES_PAGE.BANNER}/>
        <div className="page__content bg--white">
          <SubMenuComponent data={SERVICES_PAGE.SUB_MENU} page="serivces"/>
          {
            SERVICES_PAGE.SERVICES.map((item, index) => {
              return (
                <section className={`section__wrapper card__service ${index % 2 ? 'card__service--reverse' : ''}`} key={index} id={SERVICES_PAGE.SUB_MENU[index].id}>
                  <div className="max__width">
                    <div className="card__service__header">
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                      <span className="number">{item.order}</span>
                    </div>
                  </div>
                  <div className="card__service__body max__width">
                    <ProjectComponent data={item.project}/>
                    <div className="card__service__progress">
                      <div className="text--center card__service__progress__header">
                        <h3>{item.middle.title}</h3>
                        <p>{item.middle.description}</p>
                      </div>
                      <FeaturesComponent data={item.services}/>
                    </div>
                  </div>
                  <QuoteServiceComponent data={item.quote} hideHeader />
                </section>
              )
            })
          }
          <EstimationComponent data={SERVICES_PAGE.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default ServicesPage;
