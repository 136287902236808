import React from 'react';

import { Link } from "react-router-dom";

const ProjectComponent = ({ data }) => {
  const {
    title,
    subTitle,
    description,
    url,
    image
  } = data;
  return (
    <div className="card__project">
      <div className="card__project__image" style={{backgroundImage: `url(/images/${image})`}} />
      <div className="card__project__description">
        <h3>{title}</h3>
        <b>{subTitle}</b>
        <p>{description}</p>
        <Link to={url} className="btn btn--secondary btn--large">
          <a href="/cients"><span>View this case study</span></a>
        </Link>
      </div>
    </div>
  )
};

export default ProjectComponent;
