import React, { PureComponent } from 'react';

import { PRODUCT_DESIGN__SPRINT } from "../../constants";

import Icon from "../../components/Icon";
import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class ProductDesignSprint extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={PRODUCT_DESIGN__SPRINT.BANNER} />
        <div className="page__content bg--white">
          <SubMenuComponent />
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Product Design Sprint at Co.H</h1>
            <p className="max__width--medium text--center">A Product Design Sprint is a workshop that we developed after years of experience, many scoping sessions,
              and dozens of kick-off meetings with our clients. The design sprint methodology was pioneered by Google
              Ventures (GV) as a tool to solve business problems through design, prototyping, and testing ideas with
              customers. The key benefit is that months of work are condensed into a few days that bring tangible
              results.</p>
            <div className="process__map">
              {
                PRODUCT_DESIGN__SPRINT.PROCESS_MAP.map((item, index) => (
                  <div className="process__map__item" key={index}>
                    <div className="process__map__icon">
                      <Icon name={item.icon} className="icon--secondary border--around"/>
                      <span>{index + 1}</span>
                    </div>
                    <b>{item.title}</b>
                  </div>
                ))
              }
            </div>
            <FeaturesComponent alignLeft data={PRODUCT_DESIGN__SPRINT.FEATURES}/>
            <div className="section--overview__container">
              <div className="text--center">
                <h2>Product Design Sprint process</h2>
                <p className="max__width--medium">A Product Design Sprint is a 5-day workshop which uses design thinking to reduce the risks inherent
                  in
                  successfully bringing products to market. This planning methodology can be effective in moving your
                  company forward, regardless the stage of your business. Each day of the sprint corresponds to another
                  stage of solving the problem. The five phases will help you understand your business ideas and needs.
                  Our
                  flow has proved to be useful in finding the right solutions, building new features, and achieving
                  goals.
                  Explore different perspectives and identify viable solutions.</p>
              </div>
              <div className="process__image">
                <img src="/images/sprint-circle.svg" alt="Sprint Circle"/>
              </div>
              <div className="max__width--medium" ref={this.myRef} id="test">
                <div className="box">
                  <h4>Monday: Understand</h4>
                  <p>The first day is all about getting insights about the user needs, business needs, and technology
                    capacities. Discussion during this stage is essential to create a roadmap for the sprint week. At
                    the very beginning, we set up long-term goals, obstacles, and challenges. We run some exercises to
                    paint a bigger picture of the problem. At the end of the day, you pick the main goal you want to
                    achieve during the sprint.</p>
                </div>
                <div className="box">
                  <h4>Tuesday: Diverge</h4>
                  <p>On the second day, we focus on generating and exploring as many ideas as possible. We’ll first
                    review existing solutions, including their strong and weak points. The participants will also
                    perform exercises to build new insights and solutions. Through brainstorming sessions and individual
                    analyses, each team member will propose their ideas that will be later studied and tested.</p>
                </div>
                <div className="box">
                  <h4>Wednesday: Converge</h4>
                  <p>At this stage, we’ll have plenty of ideas which the team came up with during the previous two
                    steps. The team’s goal now is to identify the best ideas and decide which of them should be
                    prototyped in the next step. We’ll critique each solution in relation to the main goal. Based on the
                    selected ideas, we’ll sketch a step-by-step roadmap for building a prototype.</p>
                </div>
                <div className="box">
                  <h4>Thursday: Prototype</h4>
                  <p>On the fourth day, it’s time to put all that the team developed into practice. You will turn your
                    storyboard into a prototype – a realistic artifact you will then test with a customer. The main
                    assumption here is that you will only build the customer-facing surface of your product or service.
                    In this way, the prototype will be up and running in just one day – ready for review in the final
                    stage.</p>
                </div>
                <div className="box">
                  <h4>Friday: Test</h4>
                  <p>GV calls this phase the “moment of truth”. This is when you test the ideas with users, business
                    stakeholders, and technical experts and hear direct feedback from them. This stage will validate
                    your solutions before you start spending your money on designing and developing an actual product.
                    At the end of the fifth day, you’ll have a clear idea of what you should do next.</p>
                </div>
                <div className="box">
                  <h4>After the design sprint</h4>
                  <p>In the following weeks, our team will send you a detailed report from the workshops with all
                    materials and canvases digitised including:</p>
                  <ul>
                    <li>
                      <Icon name="arrow-right" />
                      <span>Lo-fi prototypes for some of the user stories</span>
                    </li>
                    <li>
                      <Icon name="arrow-right" />
                      <span>A project roadmap, broken down into phases</span>
                    </li>
                    <li>
                      <Icon name="arrow-right" />
                      <span>Pre-defined tasks for each phase</span>
                    </li>
                    <li>
                      <Icon name="arrow-right" />
                      <span>User journey maps, storyboards, and information architecture diagrams.</span>
                    </li>
                  </ul>
                </div>
                <div className="box">
                  <h4>Benefits of design sprint</h4>
                  <p>Depending on the purpose of the Product Design Sprint and the initial problem there is to be
                    solved, you will get tailored solutions and recommendations that will help you generate a unique
                    Value Proposition for the existing or potential customers. Product Design Sprints include in-depth
                    interviews with founders about their product idea, our design thinking, and the agile approach. They
                    have been successfully tested in numerous projects. Clients confirm that they provide an actionable
                    product roadmap for product development or prevalidated prototype.</p>
                </div>
                <div className="box">
                  <h4>Simple solutions</h4>
                  <p>Design sprints encourage effective communication, sharing ideas, and doubts. The approach enables
                    getting answers to complicated problems.</p>
                </div>
                <div className="box">
                  <h4>Users understanding</h4>
                  <p>The workshops members have to act with empathy toward the customer’s needs. The sprint is based on
                    is listening, trust and building meaningful relationships with users.</p>
                </div>
                <div className="box">
                  <h4>Increased efficiency</h4>
                  <p>The sprint itself is meant to be short and agile, as it is easily adapted to the complexity of a
                    project. It speeds up new product discovery.</p>
                </div>
                <div className="box">
                  <h4>Lower risk</h4>
                  <p>The sprint shrinks months of development and feature testing into just a few days. The faster you
                    prototype, the faster you can validate your idea.</p>
                </div>
                <div className="box">
                  <h4>Is PDS for your business?</h4>
                  <p>A Product Design Sprint can be effective in moving your company forward, regardless the stage of
                    your business. Each and every workshop is tailored to the client’s needs. Depending on your
                    challenges and questions, the Co.H design sprint team will conduct custom exercises that will help
                    you achieve your goals.</p>
                </div>
                <div className="box">
                  <h4>A rough idea for a product</h4>
                  <p>We’ll help you shape and test different solutions to build a prototype.</p>
                </div>
                <div className="box">
                  <h4>A product you want to improve</h4>
                  <p>We’ll verify product’s weak points and develop ideas to fix them.</p>
                </div>
                <div className="box">
                  <h4>New product features to test</h4>
                  <p>We’ll consider different scenarios for product further development.</p>
                </div>
                <div className="box">
                  <h4>An MVP that needs UX/UI design</h4>
                  <p>We’ll develop UX and appealing design that will resonate with your audience.</p>
                </div>
              </div>
            </div>
          </section>

          <IndustriesComponent />
          <TestimonialsComponent />
          <EstimationComponent data={PRODUCT_DESIGN__SPRINT.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default ProductDesignSprint;
