import React from 'react';

import Header from "../Header";

const BannerComponent = ({isSubPage = false, data}) => {
  const {
    mainPage,
    img,
    title,
    description
  } = data;
  return (
    <React.Fragment>
      <section className={`section section--banner ${isSubPage ? 'section--banner--subpage' : ''}`}
               style={{backgroundImage: `url(/images/${img})`}}>
        <div className="section__content m__t--34">
          <Header page={mainPage}/>
          <div className="max__width">
            <div className="typing__wrapper">
              <h1 className="text--white">{title}</h1>
              <p className="text--white font__size--large">{description}</p>
            </div>
          </div>
        </div>
      </section>
      <div className="mark"/>
    </React.Fragment>
  )
};

export default BannerComponent;
