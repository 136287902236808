import React from 'react';
import Icon from '../Icon';
import './styles.scss';

const SOCIAL_NETWORK = [
  {
    name: 'twitter',
    path: ''
  },
  {
    name: 'instagram',
    path: ''
  },
  {
    name: 'facebook',
    path: ''
  },
  {
    name: 'linkedin',
    path: ''
  },
  {
    name: 'github',
    path: ''
  },
  {
    name: 'dribbble',
    path: ''
  },
  {
    name: 'behance',
    path: ''
  }
];

const SocialNetwork = ({isCircle = false}) => {
  return (
    <ul className="social__network">
      {
        SOCIAL_NETWORK.map((item, index) => {
          return (
            <li key={index}>
              <a href={item.path}>
                <Icon name={item.name} isCircle={isCircle} />
              </a>
            </li>
          )
        })
      }
    </ul>
  )
}

export default SocialNetwork;
