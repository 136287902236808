import React, { PureComponent } from 'react';

import { QUALITY_ASSURANCE } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import VerticalCardComponent from "../../components/VerticalCard";
import FeaturesComponent from "../../components/Features";
import BannerComponent from "../../components/Banner";
import EstimationComponent from "../../components/Estimation";

import './styles.scss';

class QualityAssurance extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub page--sub__quality">
        <BannerComponent isSubPage data={QUALITY_ASSURANCE.BANNER}/>
        <div className="page__content bg--white">
          <SubMenuComponent />
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Quality Assurance team</h1>
            <div className="max__width--medium text--center">
              <p className="m__b--10 text--gray">
                QA specialists are integral to our development teams. They
                are present at every step of the software development process, finding issues in the output of
                programmers' work, helping identify omissions and substantially improving the quality of the final
                product.
              </p>
              <p className="m__b--10 text--gray">
                Quality Assurance is important even before actual coding begins. If we identify potential threats or
                inconsistencies early in the software development process, we have a greater chance of avoiding
                additional expenses that might result from unexpected changes and modifications in the future.
              </p>
              <p className="m__b--10 text--gray">
                Every part of every developed feature is tested by our QAs in separate branches, to compare it against
                the current state of the project. This makes it easier to spot the changes and bugs which may have
                negative influence on already approved parts of the application. It's also a chance to improve things
                early, before more complex dependencies build up.
              </p>
              <p className="m__b--10 text--gray">
                When we are not testing new features, the QA team engage in one of our multiple activities to maintain
                product quality. Writing automated tests is one of the ways to protect developed applications from
                unexpected regressions. We do it both for web and mobile apps. We have internal processes, such as Bug
                Bash events, where we spend time with project teams and QAs from the outside and test applications on
                various browsers and devices.
              </p>
              <p className="m__b--10 text--gray">
                We spend hours roaming through the screens of your app to find anything which might have been missed.
                Exploratory tests are always a good way not only to find missed bugs but also suggest possible
                improvements, e.g. concerning UX/UI issues.
              </p>
              <p className="m__b--10 text--gray">
                Quality Assurance is not only testing. We act as advisors, helping to broaden your perspective and show
                you aspects of the application which might not have come to your attention. Whether it is related to the
                usability of your product, its look or the architecture, we always try to analyse it in depth. We are
                here to ask questions which have not been asked yet. With our knowledge and experience, we want to show
                you the best practices and guide you so that you are proud and amazed by the final result of your
                project.
              </p>
            </div>
            <FeaturesComponent alignLeft data={QUALITY_ASSURANCE.FEATURES}/>

            <VerticalCardComponent
              title={["Co.H’s QA process is", <span className="text--secondary"> strict</span>]}
              description={['One of clinets said: “working with Co.H was very professional and productive. I can personally say\n' +
              'that - working with many development agencies as well as with teams from abroad in many, many\n' +
              'projetcts all over the years - that I have never seen such a good performance regarding Quality\n' +
              'Assurance.” We are proud of accounts like this one, and we do everything we can to live up our\n' +
              'reputation. Our QA processes are stirct, and our QA Specialists are talented, driven people,\n' +
              'determined to make every project they work on as good as possible.']}
            />

            <VerticalCardComponent
              title={["QA Specialists are", <span className="text--secondary"> experts </span>, "on their field"]}
              description={['We expect excellence from our team and we help unlock individual potential. Clients tell us that “Co.H\n' +
              'has really good people filling their QA positions, people who are not just ‘incapable developers’,\n' +
              'like it is in many other development agencies”. This is true, and it is important to remember that\n' +
              'Quality Assurance requires a different skill set than software development.']}
            />
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={QUALITY_ASSURANCE.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default QualityAssurance;
