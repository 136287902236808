import React from 'react';


const VerticalCardComponent = ({ title, description }) => {
  return (
    <div className="card--vertical">
      <h3 className="card--vertical__title border border--left">{title}</h3>
      <div className="card--vertical__description">
        {
          description.map((item, index) => (
            <p className="m__b--10" key={index}>{item}</p>
          ))
        }
      </div>
    </div>
  )
};

export default VerticalCardComponent;
