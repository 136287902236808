import React, { PureComponent } from 'react';

import { ABOUT_US_PAGE } from "../../constants";

import BannerComponent from "../../components/Banner";
import SubMenuComponent from "../../components/SubMenu";
import EstimationComponent from "../../components/Estimation";
import QuoteComponent from "../../components/Quote";
import Icon from "../../components/Icon";

class AboutUsPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--about-us">
        <BannerComponent data={ABOUT_US_PAGE.BANNER}/>
        <div className="page__content bg--white">
          <SubMenuComponent data={ABOUT_US_PAGE.SUB_MENU}/>
          <section className="section__wrapper" id="whoWeAre">
            <div className="card__about-us max__width">
              <div className="card__about-us__header section">
                <h2>Building software for <span className="text--secondary">world changers</span></h2>
                <p>Co.H builds digital products that let people do things differently. Share your challenge with our team,
                  and we’ll work with you to deliver a revolutionary digital product. Our clients have changed the way
                  people do banking, listen to music, learn languages, and rent bikes. </p>
              </div>
            </div>
          </section>
          <section className="section__wrapper statistics bg--default">
            <div className="statistics__inner max__width">
              {
                ABOUT_US_PAGE.STATISTICS.map((item, index) => (
                  <div className="statistic__item" key={index}>
                    <h1 className="text--secondary">{item.number}</h1>
                    <h3>{item.title}</h3>
                  </div>
                ))
              }
            </div>
          </section>

          <QuoteComponent data={ABOUT_US_PAGE.QUOTES[0]}/>

          <section className="section__wrapper" id="ourValues">
            <div className="card__about-us section max__width">
              <div className="card__about-us__header">
                <h2>Our values <span className="text--secondary">shape the way we</span> work with our clients, delivering
                  outstanding user experience supported by
                  cutting-edge technologies</h2>
                <p>Co.H’s core values evolved with us as the company grew and we learned from our experiences. They
                  epitomise what we want to achieve, and they might change in the future, as we aim higher and higher. We
                  think of those values as important to our culture and individual sense of accomplishment.</p>
              </div>
              <div className="card__about-us__body">
                <div className="card__list">
                  {
                    ABOUT_US_PAGE.OUR_VALUES.map((item, index) => (
                      <div className="card__item" key={index}>
                        <Icon name={item.icon} isCircle className="icon--secondary"/>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </section>

          <section className="section__wrapper bg--default" id="howWeWork">
            <div className="section card__about-us max__width">
              <div className="card__about-us__header">
                <h2>Shaping a vision, delivering software, creating <span className="text--secondary">world class experiences</span>
                </h2>
                <p>Collaborating with Co.H has had a lasting impact on our ability to be innovative and execute at a
                  pace.
                  The team there have a strong focus on not just doing what you ask for.</p>
                <button className="btn btn--secondary btn--large">
                  <span>Our services</span>
                </button>
              </div>
              <div className="process">
                {
                  ABOUT_US_PAGE.PROGRESS.map((item, index) => (
                    <div className="process__item" key={index}>
                      <span>0{index + 1}</span>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </section>

          <QuoteComponent data={ABOUT_US_PAGE.QUOTES[1]}/>

          <section className="section__wrapper" id="ourTeam">
            <div className="card__about-us section max__width">
              <div className="card__about-us__header">
                <h2>Meet talent & experience, <span className="text--secondary">meet the team</span></h2>
                <p>Diversity of people, knowledge, and experience. Shared values, culture, and goals. Thanks to them we
                  are a unique team. The team, that is our greatest strength.</p>
              </div>
              <div className="team">
                {
                  ABOUT_US_PAGE.TEAM.map((item, index) => (
                    <div className="team__item text--center" key={index}>
                      <div className="team__avatar">
                        <img src={`/images/avatar/${item.avatar}`} alt="Avatar"/>
                      </div>
                      <h3>{item.name}</h3>
                      <p>{item.position}</p>
                    </div>
                  ))
                }
              </div>
              <div className="special__box">
                <div className="box__left">
                  <h3 className="border border--left">What makes us special?</h3>
                  <p>At Co.H, the work culture and core values are essential. We know that teamwork can beat distance, we
                    share the need for self-development, and believe that at Co.H you can be whoever you want to
                    be.</p>
                  <button className="btn btn--secondary btn--large">
                    <span>Careers</span>
                  </button>
                </div>
                <div className="box__right">
                  <div className="card">
                    <Icon name="seed-outline" isCircle className="icon--secondary"/>
                    <p>
                      Valuing communication and transparency. We are not afraid of making mistakes, but always remember to
                      learn lessons.
                    </p>
                  </div>
                  <div className="card">
                    <Icon name="voice" isCircle className="icon--secondary"/>
                    <p>Sharing information about Co.H openly. You can check the pay grade and ask about anything. We
                      believe that trust is the key.</p>
                  </div>
                  <div className="card">
                    <Icon name="presentation-play" isCircle className="icon--secondary"/>
                    <p>A growth budget and supporting knowledge sharing. We endorse constructive feedback and we’re
                      happy to support team members who want to speak at conferences.</p>
                  </div>
                  <div className="card">
                    <Icon name="map-check" isCircle className="icon--secondary"/>
                    <p>Working from wherever and when we want. Thanks to appropriate processes and tools, following the
                      “remote-first” approach, is possible.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <EstimationComponent data={ABOUT_US_PAGE.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default AboutUsPage;
