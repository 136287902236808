import React, { PureComponent } from 'react';

import { ANDROID } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import ProjectComponent from "../../components/Project";
import EstimationComponent from "../../components/Estimation";
import FeaturesComponent from "../../components/Features";

class Android extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={ANDROID.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Android development team</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Android has the majority of the mobile market share. Developing an Android app will allow you to reach
                the widest user base possible. Using the Java and Kotlin programming languages allows our experienced
                team to build beautiful and scalable apps with top performance. The variety of functions served by
                native libraries allows to leverage the capabilities of the newest Android smartphones. Third party
                libraries allow us to speed up development and concentrate on the business value of the project.
              </p>
              <p className="text--gray m__b--10">
                As an Android application development company, our app developers have very high standards of code
                quality. Co.H offers comprehensive end-to-end, full-stack mobile app development services. Our Android
                developers will help you during the whole Android application lifecycle: from mobile app idea to
                development and publishing it on the Google Play Store.
              </p>
              <p className="text--gray m__b--10">
                Our Android application developers are highly skilled and follow agile methodologies to deliver secure
                Android apps. Co.H has developed Android applications ranging from small utility apps to enterprise
                solutions for a variety of industries. We create customized, scalable, end-to-end applications for
                Android devices, including smartphones, tablets, and Android TVs.
              </p>
              <p className="text--gray m__b--10">
                As an Android app development company, Co.H provides services to meet the specific business requirements
                of customers across market verticals. We specialize in customizing, developing, designing, testing, and
                launching Android applications.
              </p>
            </div>
            <ProjectComponent data={ANDROID.PROJECT}/>
          </section>

          <section className="section">
            <h1 className="border border--center">Why you should check out our services</h1>
            <FeaturesComponent data={ANDROID.FEATURES}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={ANDROID.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default Android;
