import React from 'react';

import Icon from "../Icon";
import Avatar from "../Avatar";

const QuoteComponent = ({ data }) => {
  const {
    quote,
    avatar,
    name,
    position,
    background,
  } = data;
  return (
    <div className="quote background background--bw" style={{backgroundImage: `url(/images/${background})`}}>
      <div className="quote__inner max__width">
        <Icon name="format-quote-close" />
        <p>{quote}</p>
        <div className="author">
          <Avatar avatar={avatar} size={40}/>
          <h4>{name}</h4>
          <p>, {position}</p>
        </div>
      </div>
    </div>
  )
};

export default QuoteComponent;
