import React from 'react';
import {Link} from "react-router-dom";

const EstimationComponent = ({ data }) => {
  const {
    title,
    description,
    background
  } = data;
  return (
    <section className="section__wrapper background" style={{backgroundImage: `url(/images/${background})`}}>
      <div className="section--estimate max__width">
        <h2>{title}</h2>
        {
          description !== '' && <p className="text--white">{description}</p>
        }
        <Link className="btn btn--secondary btn--large" to="/estimation">
          <span>Estimate project</span>
        </Link>
      </div>
    </section>
  )
};

export default EstimationComponent;
