import React from 'react';
import { Link } from "react-router-dom";

import { FOOTER } from "../../constants";

import SocialNetwork from "../SocialNetwork";

const Footer = () => {
  return (
    <footer className="section section--footer bg--white">
      <div className="d__flex align__items--center justify__content--between">
        <i className="logo icon-logo"/>
        <SocialNetwork isCircle/>
      </div>
      <div className="footer__list">
        {
          FOOTER.map((footer, index) => {
            return (
              <div key={index} className="footer__item">
                <h3>{footer.title}</h3>
                <ul>
                  {
                    footer.urls.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.path}>{item.name}</a>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            )
          })
        }
      </div>
      <div className="d__flex align__items--center justify__content--between">
        <p className="text--gray font__size--small">Co.H, 2019. All rights serves</p>
        <ul className="d__flex align__items--center">
          <li className="m__r--20">
            <Link className="text--gray font__size--small font__weight--bold" to="">Privacy</Link>
          </li>
          <li>
            <Link className="text--gray font__size--small font__weight--bold" to="">Terms & Conditions</Link>
          </li>
        </ul>
      </div>
    </footer>
  )
};

export default Footer;
