import React, { PureComponent } from 'react';

import { FLUTTER } from "../../constants";
import Icon from "../../components/Icon";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import VerticalCardComponent from "../../components/VerticalCard";

import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import ProjectComponent from "../../components/Project";
import EstimationComponent from "../../components/Estimation";

import './styles.scss';

class Flutter extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub page--sub__flutter">
        <BannerComponent isSubPage data={FLUTTER.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Cross-platform Mobile App Development Made Easy</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Flutter is Google's mobile app software development kit (SDK) made to build cross-platform applications.
                It's a complete environment with a framework, widgets, and tools, which gives you the opportunity to
                develop mobile apps efficiently. Thanks to rich widgets, Flutter apps look and feel great. You can
                create your own custom app design or use ready UI elements following specific platforms’ guidelines.
              </p>
              <p className="text--gray m__b--10">
                Flutter includes a modern React-style framework where apps are written in Dart. Thanks to its rich
                documentation even a novice developer can quickly build a prototype with it. Google engineers tried hard
                to deliver as many competitive advantages as possible, which makes the mobile app development process
                smooth while reducing the cost of app production at the same time.
              </p>
            </div>
            <FeaturesComponent alignLeft data={FLUTTER.FEATURES}/>
            <ProjectComponent data={FLUTTER.PROJECT}/>
            <VerticalCardComponent
              title={['Get native UX with a single',
                <span className="text--secondary"> cross-platform code base</span>]}
              description={[
                'Flutter is an open source software, so everyone has access to the source code and can use it for free. ' +
                'The SDK uses a C++ rendering engine and is based on the Dart programming language.',
                'Flutter provides widgets out of the box, and its architecture is based on reactive programming, which ' +
                'means it\'s asynchronous, with data streams and propagation of change. This makes the app responsive ' +
                'and gives perfect usability to the user. Flutter also has some significant advantages over other ' +
                'Android and iOS SDKs.'
              ]}
            />
          </section>

          <section className="section">
            <h1 className="border border--center">Customizable UI widgets</h1>
            <div className="max__width--medium text--center">
              <p>
                The development model is based on objects. In Flutter, each object - button or a font - is a widget.
                Widgets are at the core of the Flutter concept: using them is a lot like building with blocks. At the
                same
                time, your Flutter development team gets access to the lowest level of the code, where they can create
                new
                widgets - designed for your needs. They will do so using the same tools the Flutter team used to build
                the
                default widgets.
              </p>
            </div>
          </section>

          <section className="section">
            <h1 className="border border--center">Easy to learn</h1>
            <div className="max__width--medium text--center">
              <p>
                When choosing a technology, you should always take a closer look at how many people are using it and how
                steep the learning curve is. In the case of Flutter, both are very promising. While the main challenge
                is the knowledge of Dart (frameworks like React Native use the more popular JavaScript), even beginner
                developers can build quick prototypes and apps with Flutter. Thanks to Google's support, the young
                technology is growing rapidly. This means that there are more and more young engineers using this
                technology, making it more accessible to businesses.
              </p>
            </div>
          </section>

          <section className="section">
            <h1 className="border border--center">Dart is modern and effective</h1>
            <div className="max__width--medium text--center">
              <p>
                The language behind Flutter is similar to C++ and Java. Dart is accessible even for beginners thanks to
                its unsurprising object orientation and syntax. Additionally, it combines both ahead-of-time (AOT) and
                just-in-time (JIT) compilation, which gives you high performance of the app. Unlike the languages used
                in native Android development, Dart does not need separate XML files from the layout, which means faster
                and easier development.
              </p>
            </div>
          </section>

          <section className="section">
            <h1 className="border border--center">Cross-cultural accessibility</h1>
            <div className="max__width--medium text--center">
              <p>
                With Flutter you don't have to worry about internationalization: Flutter supports 24 languages. Apart
                from this, it has built-in currencies, dates, units of measure, as well as layout options adapted to the
                languages that are not written from left to right. You have all this included in the Dart intl package.
                Moreover, Flutter is fully accessible by providing large fonts, enhanced contrast, and screen readers
                for users who need it.
              </p>
            </div>
          </section>

          <section className="section">
            <h1 className="border border--center">Superb documentation</h1>
            <div className="max__width--medium text--center">
              <p>
                Flutter is easy to learn. While the main challenge is getting to know Dart (React Native uses the more
                popular JavaScript), even beginner developers can build fast prototypes and apps with Flutter. Thanks to
                Google's supervision the young technology is growing very fast. Flutter has clear and well-written
                documentation and there are valuable tutorials available online. This means that there are more and more
                young engineers using this technology, making it more accessible to businesses.
              </p>
            </div>
          </section>

          <section className="section">
            <h1 className="border border--center">What are the main benefits of using Flutter?</h1>
            <div className="features__container features__container--flutter">
              {
                FLUTTER.FEATURES_2.map((item, index) => (
                  <div className="feature__item" key={index}>
                    <Icon name={item.icon} isCircle className="icon--secondary"/>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                ))
              }
            </div>
          </section>

          <section className="section">
            <div className="max__width--medium">
              <div className="box">
                <h4>The differences between Flutter and React Native?</h4>
                <p>
                  If you want to deliver a product for both Android and iOS users, you have two options. You can either
                  make two separate native applications or use hybrid development and write a codebase that can be shared
                  by both operating systems. This is what React Native and Flutter do. Both environments are open-source
                  and free to use. So what are the differences?
                </p>
              </div>
              <div className="box">
                <h4>JavaScript vs. Dart</h4>
                <p>
                  From the inside - it's one of the biggest differences. The popularity of React Native is powered by
                  JavaScript, which is the most frequently used software development technology at the moment. However,
                  Google wanted to come up with something equally good, making Dart easy to learn for the developers.
                </p>
              </div>
              <div className="box">
                <h4>Widgets vs. direct communication with iOS and Android</h4>
                <p>
                  Talking about flexibility and customization, Flutter offers a rich set of customized widgets to build
                  beautiful experiences. React Native, on the other side, offers seamless user experience by directly
                  communicating with the native platforms.
                </p>
              </div>
              <div className="box">
                <h4>External packages</h4>
                <p>
                  Both Flutter and React Native have many third-party packages, however React Native offers more
                  ready-to-use modules. Flutter was only officially launched in May 2017, so its packages aren't that
                  numerous. However, this should change with time.
                </p>
              </div>
              <div className="box">
                <h4>Established vs. fast-growing community</h4>
                <p>
                  React Native was the first hybrid development framework and it uses JavaScript - the most dominant
                  technology for building mobile and web applications. This is enough to gather an impressive community of
                  professionals around it. Ultimately, many solutions to common problems are already in place. On the
                  other hand, Google consistently promotes its solution and Flutter is winning over developers.
                </p>
              </div>
              <div className="box">
                <h4>Code recycling and styling</h4>
                <p>
                  With Flutter you can overwrite the code you previously used. This feature is very useful when you want
                  to reuse some of the code for another purpose. In React Native recycling is restricted only to a handful
                  of basic components, which is why in most cases it takes a lot of time to style apps and platforms built
                  with React Native.
                </p>
              </div>
              <div className="box">
                <h4>New vs. Old</h4>
                <p>
                  At the moment, React Native is the go-to hybrid development toolkit. There are more libraries, use
                  cases, and community resources for React Native. On the other hand, Flutter (and Dart) were designed
                  using all the hybrid app development experience. It addresses the main issues, which are the
                  compatibility of platform-specific modules and UI elements with the hybrid solution. It may take some
                  time, but Flutter should catch up. With more than 30,000 Github stars already, Flutter is quickly
                  cutting the distance to React Native, which has 65,000 stars.
                </p>
              </div>
            </div>
            <VerticalCardComponent
              title={['For which applications flutter',
                <span className="text--secondary"> flutter </span>, 'is a good solution?']}
              description={[
                'From the business point of view, Flutter has two main advantages - it\'s very fast to build with and it ' +
                'works great on both Android and iOS. If you need a prototype or an MVP fast, want to target both Android ' +
                'and iOS users, deliver a beautiful experience similar to native apps, and at the same time be able to ' +
                'quickly adjust to feedback from users - you should definitely consider building your product with Flutter.'
              ]}
            />
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={FLUTTER.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default Flutter;
