import React, { PureComponent } from 'react';
import TextField, { Input } from '@material/react-text-field';
import { Link } from "react-router-dom";
import ReactNotification, { store } from 'react-notifications-component';
import emailjs from 'emailjs-com';

import { env } from '../../environtment';
import Header from "../../components/Header";
import SocialNetwork from '../../components/SocialNetwork';
import Icon from "../../components/Icon";
import HomeCarouselComponent from "../../components/HomeCarousel";
import { HOME_PAGE } from "../../constants";
import FeaturesComponent from "../../components/Features";

class HomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phoneNumber: '',
      typeOfInquiry: '',
      message: '',
      isFirstCheck: false,
      isSecondCheck: false,
      isSending: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSendEmail = () => {
    const {
      email,
      phoneNumber,
      typeOfInquiry,
      message,
    } = this.state;

    const homeTemplateParams = {
      "email": email,
      "phoneNumber": phoneNumber,
      "typeOfInquiry": typeOfInquiry,
      "message": message,
    };
    this.setState({isSending: true});
    emailjs.send(env.serviceId, env.homeTemplateId, homeTemplateParams, env.userServiceId).then(() => {
      store.addNotification({
        message: "Send email successfully!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      });
      this.setState({
        email: '',
        phoneNumber: '',
        typeOfInquiry: '',
        message: '',
        isFirstCheck: false,
        isSecondCheck: false,
        isSending: false
      })
    }, (err) => {
      console.log('FAILED...', err);
      this.setState({isSending: false, isLoading: false});
    });
  };

  handleFirstCheck = () => {
    const {isFirstCheck} = this.state;
    this.setState({isFirstCheck: !isFirstCheck});
  };

  handleSecondCheck = () => {
    const {isSecondCheck} = this.state;
    this.setState({isSecondCheck: !isSecondCheck});
  };

  render() {
    const {
      email,
      phoneNumber,
      typeOfInquiry,
      message,
      isFirstCheck,
      isSecondCheck,
      isSending,
    } = this.state;

    const isCanSubmit = isFirstCheck && isSecondCheck && !isSending;

    return (
      <div className="page page--home">
        <ReactNotification/>
        <section className="section section--banner" style={{backgroundImage: `url('/images/header-bg-1.jpg')`}}>
          <div className="section__content">
            <div className="social__network__box animated fadeIn">
              <SocialNetwork/>
            </div>
            <Header page="home"/>
            <div className="text--center max__width">
              <h4 className="text--white">Are you looking for a tech team could resolve your problem ? <br/>Web, Mobile
                App and Machine Learning ?</h4>
              <div className="typing__wrapper">
                <h1 className="text--white">Yes, We can build that !</h1>
              </div>
              <a href="/estimation" className="btn btn--large btn--secondary">
                <span>Estimate your project here</span>
              </a>
            </div>
            <div className="scroll__down">
              <Icon name="chevron-down"/>
              <span className="text--white">scroll down to discover our services</span>
            </div>
          </div>
        </section>
        <div className="mark"/>
        <div className="page__content">
          <section className="section__wrapper bg--default">
            <div className="section section--services max__width">
              <h3 className="section__title">Let’s see what we can do !</h3>
              <div className="service__container">
                {
                  HOME_PAGE.SERVICES.map((service, index) => {
                    return (
                      <div key={index} className="service__item">
                        <div className="service__item--icon">
                          <img src={service.icon} alt="Service Icon"/>
                        </div>
                        <h5>{service.title}</h5>
                        <ul className="service__tech">
                          {
                            service.techList.map((tech, index) => {
                              return (
                                <li key={index}>
                                  {
                                    !!tech.path ? <Link to={tech.path}>{tech.name}</Link>
                                      : <span>{tech.name}</span>
                                  }
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>

          <section className="section__wrapper bg--white">
            <div className="section section--process max__width">
              <h3 className="section__title">And this is how we do in an usual ways</h3>
              <FeaturesComponent data={HOME_PAGE.FEATURES}/>
            </div>
          </section>

          <section className="section__wrapper bg--secondary">
            <div className="max__width section section--case-study">
              <h3 className="section__title text--white">Some projects that we so proud to show</h3>
              <HomeCarouselComponent/>
            </div>
          </section>

          <section className="section__wrapper bg--white">
            <div className="section section--stories max__width">
              <h3 className="section__title">We have some stories to tell</h3>
              <div className="story__container">
                <div className="story__card story__card--main"
                     style={{backgroundImage: `url('/images/header-bg.jpg')`}}>
                  {/*<img src={HeaderBg} alt="Story"/>*/}
                  <div className="story__card__content">
                    <h4>How to build an office that could effect to productivity of ours start-up teams</h4>
                    <div className="story__card__author">
                      <div className="avatar">
                        <img src='/images/avatar.png' alt="Avatar"/>
                      </div>
                      <span>Quoc Nguyen</span>
                    </div>
                  </div>
                </div>
                <div className="story__card" style={{backgroundImage: `url('/images/header-bg.jpg')`}}>
                  {/*<img src={HeaderBg} alt="Story"/>*/}
                  <div className="story__card__content">
                    <h4>How to build an office that could effect to productivity of ours start-up teams</h4>
                    <div className="story__card__author">
                      <div className="avatar">
                        <img src='/images/avatar.png' alt="Avatar"/>
                      </div>
                      <span>Quoc Nguyen</span>
                    </div>
                  </div>
                </div>
                <div className="story__card" style={{backgroundImage: `url('/images/header-bg.jpg')`}}>
                  {/*<img src={HeaderBg} alt="Story"/>*/}
                  <div className="story__card__content">
                    <h4>How to build an office that could effect to productivity of ours start-up teams</h4>
                    <div className="story__card__author">
                      <div className="avatar">
                        <img src='/images/avatar.png' alt="Avatar"/>
                      </div>
                      <span>Quoc Nguyen</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d__flex justify__content--center">
                <button className="btn btn--secondary btn--large">
                  <span>Read more at here</span>
                </button>
              </div>
            </div>
          </section>

          <section className="section__wrapper bg--default">
            <div className="two__column max__width section section section--contact">
              <div className="two__column__item">
                <h3>Fell free to talk to us !<br/>
                  We really good at listening !<br/>
                  ... even grab some beers too :)</h3>
                <p className="m__b--10">or you need to estimate your project here</p>
                <Link className="btn btn--medium btn--outline" to="/estimation">
                  <span>Estimate project</span>
                </Link>
              </div>
              <div className="two__column__item">
                <div className="contact__form">
                  <TextField
                    className="input"
                    label='Your email'
                    onTrailingIconSelect={() => this.setState({email: ''})}
                  >
                    <Input
                      value={email}
                      onChange={(e) => this.setState({email: e.currentTarget.value})}
                    />
                  </TextField>
                  <TextField
                    className="input"
                    label='Your phone number'
                    onTrailingIconSelect={() => this.setState({phoneNumber: ''})}
                  >
                    <Input
                      value={phoneNumber}
                      onChange={(e) => this.setState({phoneNumber: e.currentTarget.value})}
                    />
                  </TextField>
                  <TextField
                    className="input"
                    label='Type of inquiry'
                    onTrailingIconSelect={() => this.setState({typeOfInquiry: ''})}
                  >
                    <Input
                      value={typeOfInquiry}
                      onChange={(e) => this.setState({typeOfInquiry: e.currentTarget.value})}
                    />
                  </TextField>
                  <TextField
                    className="input"
                    label='Your message'
                    onTrailingIconSelect={() => this.setState({message: ''})}
                    textarea
                  >
                    <Input
                      value={message}
                      onChange={(e) => this.setState({message: e.currentTarget.value})}
                    />
                  </TextField>
                </div>

                <p className="m__t--30">Co.H is committed to processing the above information in order to contact you
                  and talk about your project. Other information is used for statistical purposes and, from time to
                  time, we would like to contact you about our other products and services, as well as other content
                  that may be of interest to you. If you consent to contact you for this purposes, please tick
                  below:</p>
                <div className={`checkbox m__t--20 ${isFirstCheck ? 'checkbox--active' : ''}`}
                     onClick={this.handleFirstCheck}>
                  <span className="checkbox__mark"/>
                  <label>I agree to talk about my project with Co.H.</label>
                </div>

                <div className={`checkbox m__t--20 ${isSecondCheck ? 'checkbox--active' : ''}`}
                     onClick={this.handleSecondCheck}>
                  <span className="checkbox__mark"/>
                  <label>I agree to receive other communications from Co.H.</label>
                </div>
                <p className="m__t--20">You can unsubscribe from these communications at any time. For more information
                  on how to
                  unsubscribe, our privacy practices please view our Privacy Policy.</p>
                <button
                  disabled={!isCanSubmit}
                  className={`btn btn--secondary btn--large m__t--30 min__w--220 ${!isCanSubmit ? 'btn--disabled' : ''}`}
                  onClick={this.handleSendEmail}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default HomePage;
