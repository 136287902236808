import React, { PureComponent } from 'react';
import Header from "../Header";

class SubMenuComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sectionActive: '',
      isShowMenu: false,
      lastScrollPos: 0,
      isFirst: false,
      data: this.props.data ||
        [
          {
            id: 'overview',
            name: 'Overview'
          },
          {
            id: 'industries',
            name: 'Industries'
          },
          {
            id: 'testimonials',
            name: 'Testimonials'
          }
        ],
      visible: true
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    const data = this.state.data.map((item) => {
      const section = document.getElementById(item.id);
      if (section) {
        item.top = document.getElementById(item.id).offsetTop + window.innerHeight;
        return item;
      }
    });
    this.setState({data});
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop + window.innerHeight,
        behavior: 'smooth',
      });
      this.handleScroll();
    }
  };

  handleScroll = () => {
    const {data, lastScrollPos} = this.state;

    const currentScrollPos = window.pageYOffset;
    this.setState({ isFirst: currentScrollPos >= window.innerHeight});
    if (lastScrollPos < currentScrollPos) {
      this.setState({
        lastScrollPos: window.pageYOffset,
        isShowMenu: false,
      });
    } else if (lastScrollPos > window.pageYOffset) {
      setTimeout(() => {
        this.setState({
          lastScrollPos: window.pageYOffset,
          isShowMenu: currentScrollPos >= window.innerHeight
        });
      }, 200)
    }
    data.forEach(item => {
      if (currentScrollPos > item.top - 30) {
        this.setState({sectionActive: item.id});
      }

      if (currentScrollPos < data[0].top) {
        this.setState({sectionActive: ''})
      }
    });
  };

  render() {
    const {data, sectionActive, isShowMenu, isFirst} = this.state;
    const { page } = this.props;
    return (
      <div className={`menu--sub ${isFirst ? 'menu--sub__fixed' : ''}`}>
        {isShowMenu && <Header page={page}/>}
        {
          data.length > 0 &&
          <ul className="d__flex justify__content--around p__v--20">
            {
              data.map((item) => (
                <li className={sectionActive === item.id ? 'active' : ''} key={item.id}
                    onClick={() => this.scrollToSection(item.id)}>{item.name}</li>
              ))
            }
          </ul>
        }
      </div>
    )
  }
}

export default SubMenuComponent;
