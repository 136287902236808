import React from 'react';
import { Link } from "react-router-dom";
import Menu from "../Menu";

const Header = ({page}) => {
  return (
    <header className="header animated fadeIn">
      <Link to="/" className="logo__box">
        <i className="logo icon-logo"/>
      </Link>
      <Menu active={page}/>
      <Link className={`btn btn--medium btn--outline-white ${page === 'estimation' ? 'btn--estimation' : ''}`}
            to="/estimation">
        <span>Estimate project</span>
      </Link>
    </header>
  )
};

export default Header;
