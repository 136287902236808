import React, { PureComponent } from 'react';

import { UX_SERVICES } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import ProjectComponent from "../../components/Project";
import EstimationComponent from "../../components/Estimation";

class UXServices extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={UX_SERVICES.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent data={UX_SERVICES.SUB_MENU}/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">User Experience Design at Co.H</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                At Co.H, we always make sure the experiences we craft are not only delightful, but also provide
                additional value to your users and your business. This is why UX design plays an important role in our
                digital transformation process.
              </p>
              <p className="text--gray m__b--10">
                Co.H is an experienced UX design agency. We provide you with a whole range of services, from business
                idea to product delivery. Firstly, you can create a unique value proposition before design or
                development starts. We offer various research methods to collect user insights that are necessary to
                adjust your product to your users’ needs. Our evaluation methods will increase the functional and
                business value of the product you are building. Our UX design team will help you grow conversion rates,
                increase time spent in the app and improve customer feedback.
              </p>
              <p className="text--gray m__b--10">
                At Co.H, we understand that crafting a digital product is not an easy job. It requires in-depth
                knowledge about your users, their behaviour and needs. In order to obtain valuable insights you will
                need to leverage the right tools. Years of experience have enabled our designers to develop and verify
                distinct methods which improve product usability, get more traffic and increase conversions. With our UX
                services your users will have an experience so delightful that they’ll recommend your product to
                everyone.
              </p>
              <p className="text--gray m__b--10">
                This is how we make it happen.
              </p>
            </div>

            <div className="special__box">
              <div className="box__left">
                <h3 className="border border--left">UX design agency - Create products that sell</h3>
                <p>
                  Building a great interface goes way beyond just making it beautiful. A powerful UI takes users on a
                  pleasant journey with your digital product and unlocks an exceptional UX. It requires a lot of testing
                  and a thorough understanding of your users’ behaviour, needs and problems. That’s where our UX
                  services come into play. Depending on the challenge you face, our UX Design Agency will help you
                  achieve your and your customers’ goals.
                </p>
              </div>
              <div className="box__right">
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Improve conversion - increase the number of leads and sales from your website and mobile
                    applications.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Sell with contents - give users the right information at the right time and in the right place.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Streamline experience - discover bottlenecks in the users journey and fix them.
                  </p>
                </div>
                <div className="card">
                  <div className="avatar avatar--medium"/>
                  <p>
                    Refine website structure - create intuitive and user-friendly navigation.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="section" id="benefits">
            <h1 className="border border--center">UX and UI: The Perfect Duo</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Each product’s design consists of two codependent parts - user experience (UX) and user interface (UI).
                UX is the structural representation of the product’s content and its features. A good UX design should
                be based on thorough research, data analysis, and knowledge about the motorical and psychological
                abilities of the end-user group. It should also take into account the general brand strategy, KPIs, and
                business goals & obstacles.
              </p>
              <p className="text--gray m__b--10">
                UX is about making things usable and practical, keeping the simplest possible visual styling. UI, or
                user interface, focuses on usability obtained through a proper, eye-pleasing layout. User interface is
                not only about the app screens. A designer needs to set the color palette, contrast, typography, and the
                final dimensions of components keeping in mind such facets as grid, baseline, responsiveness, and
                corporate brand identity.
              </p>
            </div>
          </section>

          <section className="section" id="process">
            <h1 className="border border--center">UX Design</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                User experience design lies at the base of product design process, which in a classic scenario consists
                of 7 steps. Starting with the project ideation and setup stage, we move further through ideation and
                concept, then wireframing, user testing, design, implementing recommendations, and finally design
                handover, which is the project’s termination.
              </p>
              <p className="text--gray m__b--10">
                The first four steps are a part of UX design, whereas the other three form UI design. UX designers
                should first get to know the potential product’s customers as well as they can. They may conduct
                interviews, lead workshops or spend long hours reading about brand strategy or the end-user group. After
                the UX designer gathers enough information, they begin preparing user flow maps, sitemaps, and product
                prototypes which subsequently are tested with a group of chosen users.
              </p>
            </div>
            <FeaturesComponent secondary data={UX_SERVICES.FEATURES}/>
          </section>

          <section className="section" id="uxServices">
            <h1 className="border border--center">A mindset that proves success. Your success.</h1>
            <FeaturesComponent alignLeft data={UX_SERVICES.FEATURES_2}/>
            <ProjectComponent data={UX_SERVICES.PROJECT}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={UX_SERVICES.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default UXServices;
