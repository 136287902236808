import React, { PureComponent } from 'react';

import { DIGITAL_TRANSFORMATION } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import ProjectComponent from "../../components/Project";
import EstimationComponent from "../../components/Estimation";

class DigitalTransformation extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={DIGITAL_TRANSFORMATION.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center max__width--medium">Digital transformation means doing things in a new
              (digital) way</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Digital transformation, digitization, and digital disruption are some of the most popular buzzwords
                today. There is a good reason for that. Digital transformation solutions may help to increase revenue by
                over 30%. A recent study has shown that 89% of digital organizations have plans to adopt a digital-first
                business strategy. Only 44% of them have fully adopted this approach.
              </p>
              <p className="text--gray m__b--10">
                Digital business transformation is the transformation of business activities, processes, products, and
                models with the aim to fully leverage the opportunities of digital technologies. It brings people,
                business, and technology together. It creates new customer experiences.
              </p>
              <p className="text--gray m__b--10">
                Digital transformation helps to improve efficiency, reduce costs, control finances, and increase
                security. It can also mitigate risks or lead to discovering new monetization opportunities. A good
                digital transformation strategy enables companies to find and apply new types of innovation. It helps to
                increase creativity, think out of the box, and create new ways of organizing processes. It’s possible
                thanks to the use of new technologies like big data, cloud computing, machine learning or augmented
                analytics.
              </p>
              <p className="text--gray m__b--10">
                Technologies in business are important, but digital transformation is all about people. A successful
                digital transformation starts with a leadership culture – leadership that inspires a company to evolve
                with its customers across all channels. It helps companies to engage users, build trust, and attract new
                customers. New technologies in business drive more value for both employees and customers.
              </p>
              <p className="text--gray m__b--10">
                Digital transformation is a survival issue for many companies, but also an opportunity to grow fast.
                It’s a good time to change the business model in order to compete and not stay behind innovative
                competitors.
              </p>
              <p className="text--gray m__b--10">
                At Co.H, an experienced digital business agency, we understand that digital transformation doesn't
                enhance traditional methods. We focus on helping to discover, apply, and enable new types of innovation
                and creativity.
              </p>
            </div>
            <FeaturesComponent alignLeft data={DIGITAL_TRANSFORMATION.FEATURES}/>
            <ProjectComponent data={DIGITAL_TRANSFORMATION.PROJECT}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={DIGITAL_TRANSFORMATION.PROJECT}/>
        </div>
      </div>
    );
  }
}

export default DigitalTransformation;
