import React from 'react';
import { Link } from "react-router-dom";

import Icon from "../Icon";

const FeaturesComponent = ({ secondary = false, alignLeft = false, data }) => {
  return (
    <div className={`features__container ${secondary ? 'features__container--secondary' : ''} ${alignLeft ? 'features__container--left' : ''}`}>
      {
        data.map((service, index) => (
          <div className="feature__item" key={index}>
            <div className="relative">
              <Icon name={service.icon} isCircle className="icon--secondary"/>
              { secondary && <span className="number">{index+1}</span> }
            </div>
            <h4>{service.title}</h4>
            <p>{service.description}</p>
            {
              !!service.path &&
              <Link to={service.path} className="btn btn--secondary btn--large">
                <span>View more</span>
              </Link>
            }
          </div>
        ))
      }
    </div>
  )
};

export default FeaturesComponent;
