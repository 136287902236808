import React, { PureComponent } from 'react';

import { MACHINE_LEARNING } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class MachineLearning extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={MACHINE_LEARNING.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Machine Learning solutions</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Machine Learning opens your business to a wide variety of new opportunities. You can personalize
                customer experience, automate your processes and implement solutions that will change the way customers
                interact with your product.
              </p>
              <p className="text--gray m__b--10">
                Machine learning is used in different sectors: from retail and finance, through to healthcare, to
                education and charity. E-commerce and marketing leverage ML algorithms for their recommendation engines
                to cater for their users better. Hedge funds use ML to forecast stock prices, whereas insurance
                companies can calculate risk more accurately. Banks and other financial institutions, are able to detect
                suspicious transactions – also using Machine Learning. Medical companies use ML to diagnose medical
                conditions based on sets of symptoms. At Co.H, we created a ML-based application that uses image
                recognition algorithms to detect and remove cranes from your holiday photos.
              </p>
              <p className="text--gray m__b--10">
                Machine Learning is a set of artificial intelligence techniques that allow systems to learn directly
                from data. It gives web and mobile applications the ability to learn, adapt, and improve over time. It
                does this by processing vast amounts of data, identifying trends and patterns within it – most of which
                would not be apparent to a human being – and then making decisions and taking actions to help meet
                specific objectives.
              </p>
              <p className="text--gray m__b--10">
                While traditional software automates logic, Machine Learning automates inductive learning. A type of
                Machine Learning that has gained enormous popularity recently is Deep Learning. Deep Learning works on
                hierarchical and distributed representations of data, which means that it can reuse learnt features and
                combine them into more abstract concepts. DL algorithms use more sophisticated structures, such as
                convolutional neural networks, belief networks, or recurrent neural networks. Building them, engineers
                are vaguely inspired by biological nervous systems.
              </p>
            </div>
            <FeaturesComponent alignLeft data={MACHINE_LEARNING.FEATURES}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={MACHINE_LEARNING.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default MachineLearning;
