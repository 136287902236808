import React, { PureComponent } from 'react';

import { SCOPING_SESSION } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";


class ScopingSession extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={SCOPING_SESSION.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent />
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Scoping Sessions at Co.H</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                A scoping session is a meeting between you (i.e. our client) and our team of experts, including developers
                and a project manager. We go through all aspects of project planning: from time and cost estimation to
                scope definition. A scoping session will help you craft a custom roadmap for developing your business
                vision and make sure your project is technically outstanding.
              </p>
              <p className="text--gray m__b--10">
                At Co.H, we believe that the planning phase of your project is crucial for the overall success of the
                product. We recommend scoping session services to all our customers, as they allow us to understand and
                design your business idea better.
              </p>
              <p className="text--gray m__b--10">
                Our experience confirms that scoping sessions have a major impact on the final outcome of the products we
                create. With over 450 successful projects, we’re here to ensure your start is as frictionless as possible.
              </p>
              <p className="text--gray m__b--10">
                Through a well-established process, we guide you through one of the most important phases of your project:
                the planning. You will finish the session with a solid development plan and expert recommendations. Our
                world-class project management professionals will make sure your unique idea is translated into a
                technical action plan.
              </p>
              <p className="text--gray m__b--10">
                Craft your product with us.
              </p>
            </div>
            <FeaturesComponent alignLeft data={SCOPING_SESSION.FEATURES}/>
          </section>

          <IndustriesComponent />
          <TestimonialsComponent />
          <EstimationComponent data={SCOPING_SESSION.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default ScopingSession;
