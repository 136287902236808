import React, { PureComponent } from 'react';

import { PROGRESSIVE_WEB_APPS } from "../../constants";

import TestimonialsComponent from "../../components/Testimonials";
import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class ProgressiveWebApps extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={PROGRESSIVE_WEB_APPS.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent />
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">What is a Progressive Web App (PWA)?</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                PWAs are essentially web applications that use the latest modern web technologies (including service
                workers) to deliver awesome user-experience. They have all the functionaries of native mobile
                applications without requiring from your users to install a mobile app on their devices.
              </p>
              <p className="text--gray m__b--10">
                A Progressive Web Application takes advantage of the latest technologies to combine the best features of
                web and mobile apps. You still create a web application (HTML/CSS/JS) but thanks to a much larger web
                ecosystem, you can add native mobile features such as service workers for offline support, camera access,
                and more. It allows for delivering fast, engaging and reliable mobile experience while still having the
                reach and accessibility of a web page.
              </p>
              <p className="text--gray m__b--10">
                Progressive Web Apps use modern web APIs along with progressive enhancement to deliver cross-platform web
                applications. PWAs are much faster compared to native apps. Users spend more time in native apps, but
                mobile web reach is almost three times that of native apps. Our experienced frontend team can deliver
                state-of-the-art progressive web applications that embrace the newest available browser APIs, providing
                native experience available on your users’ every device.
              </p>
              <p className="text--gray m__b--10">
                PWAs are installable without the need for an app store. They offer a full-screen experience with the help
                of an application manifest file and can re-engage users with push notifications. A Web App Manifest allows
                controlling what a PWA will look like on a mobile device and how it will be launched. You can specify a
                home screen icon, the page that should be loaded when the application is launched, the screen orientation,
                and other specifications. When launched from a device's home screen, service workers enable a PWA to load
                instantly, regardless of the network state.
              </p>
              <p className="text--gray m__b--10">
                Thanks to progressive web apps concept you can significantly improve your conversion rates, bounce rates
                and engagement. Read more about how it’s possible.
              </p>
            </div>
          </section>
          <section className="section">
            <h1 className="border border--center">PWA: Cheaper than native platforms</h1>
            <div className="max__width--medium">
              <p className="text--center text--gray">
                Progressive Web Application development is cheaper than building two, separate native platforms for iOS
                and Android. If your business core value is not native application, just make sure that you deliver
                mobile-first experience and focus on value, not technology and overwintering. Installing the native
                application is an unnecessary barrier in most of the business for your users. Instead of demanding more
                effort from their side, make your experience accessible instantly, from any place on the globe, on any
                device with a browser. And you will be able to use killer native features, known from native.
              </p>
              <div className="section__image">

              </div>
            </div>
          </section>
          <section className="section">
            <h1 className="border border--center">Characteristics of PWA</h1>
            <FeaturesComponent data={PROGRESSIVE_WEB_APPS.FEATURES}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={PROGRESSIVE_WEB_APPS.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default ProgressiveWebApps;
