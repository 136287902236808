import React, { PureComponent } from 'react';

import { NODE_JS } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import ProjectComponent from "../../components/Project";
import EstimationComponent from "../../components/Estimation";

class NodeJs extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={NODE_JS.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Node.js development team</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                Node.js is a powerful a runtime environment that allows executing JavaScript on the server side. It's
                perfect for building fast and scalable web applications. It has gained substantial popularity thanks to
                a vibrant community that makes sure the technology is up-to-date with the latest trends. Companies such
                as Netflix, Walmart, Uber, Trello, PayPal, LinkedIn, Medium, and even NASA have leveraged Node.js as the
                backend for their web applications.
              </p>
              <p className="text--gray m__b--10">
                Node.js is a great option for companies that like to get their apps into production fast and get their
                MVP as soon as possible. Node.js was introduced in 2009 as an open-source, cross-platform, JavaScript
                based, server-side development environment. Co.H’s Node.js developer can help you build fast,
                asynchronous applications and dynamic websites. Our experienced team of programmers can bring the speed
                and efficiency of event-driven JavaScript to the back-end logic on your servers.
              </p>
              <p className="text--gray m__b--10">
                Node.js works on a unique non-blocking I/O model, making the framework the top pick for scalable and
                real-time situations we are increasingly demanding of our servers. It is an excellent fit for real-time
                applications such as chats, streaming services, or collaboration tools. Node.js also excels in
                Internet-of-Things apps and Single-Page Applications, which are extremely popular in the tech landscape.
              </p>
              <p className="text--gray m__b--10">
                Our Node.js developers can deliver a robust server side of a web application using the wide range of
                tools we have at our disposal. They have extensive knowledge of Javascript development and long
                expertise in Node.js development. We have a showcase app, GitItBack, built internally by our coders.
                We’ve also developed commercial applications with Node.js as the backend, including an American news
                platform, a UK online estate agency, and an investment platform.
              </p>
              <p className="text--gray m__b--10">
                Hire Node.js developers from Co.H to build your dynamic web applications. Our professional team adapts
                the latest tools and top technologies to improve scalable solutions. We create both simple as well as
                complex applications dedicated for all business types. Work with the top-notch Node.js development
                company and build real-time applications.
              </p>
            </div>
            <FeaturesComponent alignLeft data={NODE_JS.FEATURES}/>
            <ProjectComponent data={NODE_JS.PROJECT}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={NODE_JS.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default NodeJs;
