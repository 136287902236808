import React, { PureComponent } from 'react';

import { PRODUCT_DESIGN } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import ProjectComponent from "../../components/Project";
import EstimationComponent from "../../components/Estimation";


class ProductDesign extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={PRODUCT_DESIGN.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center">Product Design at Co.H</h1>
            <p className="max__width--medium text--center">
              Product design enables you to craft engaging interfaces for your application and give your users a
              delightful experience. Product design is a difficult process of solving potential problems which has a
              direct impact on the client's product's success or its potential failure. It’s science rather than art –
              based on research and data, but containing a pinch of magic.
            </p>

            <FeaturesComponent alignLeft data={PRODUCT_DESIGN.FEATURES}/>
            <ProjectComponent data={PRODUCT_DESIGN.PROJECT}/>

            <div className="section--overview__container">
              <div className="max__width--medium">
                <div className="box">
                  <h4>Product design services</h4>
                  <p className="m__b--10">
                    The Co.H service and product design agency specializes in a wide range of distinct digital services.
                    Our design consultancy can help you discover new opportunities for your business, validate ideas,
                    optimize processes, and bring new digital products to the market. Our design studio provides
                    services for companies from various industries and at different stages of business development.
                  </p>
                  <p>
                    Co.H's product design team consists of more than 10 experienced multidisciplinary designers. They
                    can deliver a holistic design experience whether you already have a digital product, or just want to
                    shape your idea for a new business. Our clients will always benefit from our comprehensive
                    expertise, because we work as full-stack design partners. This approach will translate into engaging
                    interfaces a delightful UX that your users will love.
                  </p>
                </div>
                <div className="box">
                  <h4>UX design</h4>
                  <p>
                    At Co.H, we always make sure the experiences we craft are not only delightful, but also provide
                    additional value to your users and your business. This is why UX design plays an important role in
                    our digital transformation process. UX designers at Co.H provide you with a whole range of services
                    from business idea to product delivery. We offer various research methods to collect customer
                    insights that are necessary to adjusting your product to your users’ needs. Our evaluation methods
                    will increase the functional and business value of the product you are building. Our UX design team
                    will help you grow conversion rates, increase time spent in the app and improve customer feedback.
                  </p>
                </div>
                <div className="box">
                  <h4>Product Design Sprint</h4>
                  <p>
                    Product Design Sprint is a workshop that we developed after years of experience, many scoping
                    sessions and kick-off meetings with our clients. The design sprint methodology was pioneered by
                    Google Ventures (GV) as a tool to solve business problems through design, prototyping, and testing
                    ideas with customers. The top value is that months of work are condensed into a few days that bring
                    tangible results.
                  </p>
                </div>
                <div className="box">
                  <h4>Thursday: Prototype</h4>
                  <p>On the fourth day, it’s time to put all that the team developed into practice. You will turn your
                    storyboard into a prototype – a realistic artifact you will then test with a customer. The main
                    assumption here is that you will only build the customer-facing surface of your product or service.
                    In this way, the prototype will be up and running in just one day – ready for review in the final
                    stage.</p>
                </div>
                <div className="box">
                  <h4>Illustrations</h4>
                  <p className="m__b--10">
                    Eighty percent of the world’s population are visualizers. This fact tells us the cruel but fair
                    truth: our clients learn, remember, and, very often, make buying decisions by looking at something,
                    in the heat of the moment. Your product needs a strong, visual reason to draw your client’s
                    attention, make them focused, and explain to them complex information quickly.
                  </p>
                  <p>
                    If the illustrations are drawn with purpose and consideration, they can lay the foundation for a
                    long-term relationship between you and your customers, which will definitely have an impact on your
                    market position.
                  </p>
                </div>
                <div className="box">
                  <h4>Design systems</h4>
                  <p>
                    Creating a design system is one of the services we offer at Co.H. Our approach and process are based
                    on our experiences and the issues we’ve encountered so far. A design system consists of two things:
                    the library and the documentation. Your visual assets, your logo, fonts, colours. A design system
                    also includes UI components, their documentation, and the code snippets to facilitate developers
                    using them across existing products.
                  </p>
                </div>
                <div className="box">
                  <h4>UX review</h4>
                  <p>
                    UX review is an affordable and fast way to radically improve your product. We will help you put your
                    app on the right track and create a competitive product that meets your business goals and expands
                    your market reach. We will assess the current state of your product and see what's hampering your
                    business success.
                  </p>
                </div>
                <div className="box">
                  <h4>Product design process</h4>
                  <p>
                    Co.H’s product and service design is based on data-driven decisions, thorough research, and detailed
                    analyses. We want to make sure that our clients will receive the expected results and achieve their
                    goals and KPIs.
                  </p>
                </div>
                <div className="box">
                  <h4>Achievements of our product design team</h4>
                  <p className="m__b--10">
                    Co.H's efforts translate into tangible results. The company as a whole, and our product design
                    specialists individually, are appreciated by design communities and on the international arena. We
                    received many awards for our projects, and Co.H's culture and values have been recognised by
                    established organisations.
                  </p>
                  <p>
                    Designs created by Co.H’s product design team have been widely appreciated by the design community:
                    they have received 17 interaction gallery features on Behance and over 40 Project of the Week awards
                    on Dribbble. Our product design team has been ranked the number one trending team on Dribbble
                    multiple times.
                  </p>
                </div>
                <div className="box">
                  <h4>What makes our design studio different</h4>
                  <p>
                    Co.H's dedicated product design team has extensive experience in crafting beautiful interfaces and
                    building user experiences. Our design process is based on thorough research and testing. Having
                    completed hundreds of commercial projects, we know what approaches and techniques are effective and
                    we leverage them in our day-to-day work.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={PRODUCT_DESIGN.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default ProductDesign;
