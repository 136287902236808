import React, { PureComponent } from 'react';
import InputRange from 'react-input-range';
import TextField, { Input } from '@material/react-text-field';
import ReactNotification, { store } from 'react-notifications-component';
import emailjs from "emailjs-com";

import { ESTIMATION_PAGE } from "../../constants";

import BannerComponent from "../../components/Banner";
import SubMenuComponent from "../../components/SubMenu";
import { env } from "../../environtment";

class EstimationPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      fullName: '',
      phoneNumber: '',
      message: '',
      isFirstCheck: false,
      isSecondCheck: false,
      isSending: false,
      typeOfDevices: [
        {
          id: 1,
          icon: 'icon-monitor',
          name: 'Web App',
          value: false,
        },
        {
          id: 2,
          icon: 'icon-cellphone-iphone',
          name: 'Mobile App',
          value: false,
        },
        {
          id: 3,
          icon: 'icon-remote-desktop',
          name: 'Other Devices',
          value: false,
        },
      ],
      scopes: [
        {
          id: 1,
          icon: 'icon-frontend',
          name: 'Front-End',
          value: false,
        },
        {
          id: 2,
          icon: 'icon-backend',
          name: 'Back-End',
          value: false,
        },
        {
          id: 3,
          icon: 'icon-ui-ux',
          name: 'Design',
          value: false,
        },
      ],
      budget: {min: 10000, max: 45000},
    }
  }

  handleChangeTypeOfDevices = (id) => {
    const {typeOfDevices} = this.state;
    const typeOfDevicesTmp = typeOfDevices.map(item => {
      if (item.id === id) {
        item.value = !item.value;
      }
      return item;
    });
    this.setState({typeOfDevices: typeOfDevicesTmp})
  };

  handleChangeScope = (id) => {
    const {scopes} = this.state;
    const scopesTmp = scopes.map(item => {
      if (item.id === id) {
        item.value = !item.value;
      }
      return item;
    });
    this.setState({scopes: scopesTmp})
  };

  handleSendEmail = () => {
    const {
      email,
      fullName,
      phoneNumber,
      message,
      typeOfDevices,
      scopes,
      budget,
    } = this.state;

    const typeOfDevicesData = typeOfDevices.filter(t => t.value).map(t => {
      return t.name
    }).toString().replace(',', ', ');
    const scopesData = scopes.filter(s => s.value).map(s => {
      return s.name
    }).toString().replace(',', ', ');

    const estimationTemplateParams = {
      "email": email,
      "fullName": fullName,
      "phoneNumber": phoneNumber,
      "message": message,
      "typeOfDevice": typeOfDevicesData,
      "scopes": scopesData,
      "budgets": `Min: ${budget.min}$ - Max: ${budget.max}$`,
    };

    this.setState({ isSending: true });
    emailjs.send(env.serviceId, env.estimationTemplateId, estimationTemplateParams, env.userServiceId).then(() => {
      store.addNotification({
        message: "Send email successfully!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      });
      this.setState({
        email: '',
        fullName: '',
        phoneNumber: '',
        message: '',
        isFirstCheck: false,
        isSecondCheck: false,
        isSending: false,
        typeOfDevices: [
          {
            id: 1,
            icon: 'icon-monitor',
            name: 'Web App',
            value: false,
          },
          {
            id: 2,
            icon: 'icon-cellphone-iphone',
            name: 'Mobile App',
            value: false,
          },
          {
            id: 3,
            icon: 'icon-remote-desktop',
            name: 'Other Devices',
            value: false,
          },
        ],
        scopes: [
          {
            id: 1,
            icon: 'icon-frontend',
            name: 'Front-End',
            value: false,
          },
          {
            id: 2,
            icon: 'icon-backend',
            name: 'Back-End',
            value: false,
          },
          {
            id: 3,
            icon: 'icon-ui-ux',
            name: 'Design',
            value: false,
          },
        ],
        budget: {min: 10000, max: 45000},
      })
    }, (err) => {
      console.log('FAILED...', err);
      this.setState({isSending: false});
    });
  };

  render() {
    const {
      budget,
      email,
      fullName,
      phoneNumber,
      message,
      typeOfDevices,
      scopes,
      isFirstCheck,
      isSecondCheck,
      isSending
    } = this.state;

    const isCanSubmit = isFirstCheck && isSecondCheck && !isSending;

    return (
      <div className="page page--careers">
        <ReactNotification/>
        <BannerComponent data={ESTIMATION_PAGE.BANNER}/>
        <div className="page__content bg--white">
          <SubMenuComponent data={[]}/>
          <section className="section__wrapper card__service">
            <div className="section max__width">
              <div className="card__service__header">
                <h2>What is <span className="text--secondary">type of devices</span> of your project?</h2>
                <span className="number">01</span>
              </div>
              <ul className="estimate__selections">
                {
                  typeOfDevices.map((item, index) => (
                    <li className={`selection__item ${item.value ? 'selection__item--active' : ''}`} key={index}
                        onClick={() => this.handleChangeTypeOfDevices(item.id)}>
                      <i className={item.icon}/>
                      <h3>{item.name}</h3>
                    </li>
                  ))
                }
              </ul>
            </div>
          </section>
          <section className="section__wrapper card__service">
            <div className="section max__width">
              <div className="card__service__header">
                <h2>What is <span className="text--secondary">scope</span> of your project?</h2>
                <span className="number">02</span>
              </div>
              <ul className="estimate__selections">
                {
                  scopes.map((item, index) => (
                    <li className={`selection__item ${item.value ? 'selection__item--active' : ''}`} key={index}
                        onClick={() => this.handleChangeScope(item.id)}>
                      <i className={item.icon}/>
                      <h3>{item.name}</h3>
                    </li>
                  ))
                }
              </ul>
            </div>
          </section>
          <section className="section__wrapper card__service">
            <div className="section max__width">
              <div className="card__service__header">
                <h2>How many <span className="text--secondary">budgets</span> you gonna spend for your project ?</h2>
                <span className="number">03</span>
              </div>
              <InputRange
                formatLabel={value => `$ ${value}`}
                step={100}
                maxValue={50000}
                minValue={5000}
                value={budget}
                onChange={budget => this.setState({budget})}/>
            </div>
          </section>
          <section className="section__wrapper card__service">
            <div className="section max__width">
              <div className="card__service__header">
                <h2>Fill the form and <span className="text--secondary">get an estimate</span></h2>
                <span className="number">04</span>
              </div>
              <div className="max__width max__width--small">
                <div className="contact__form">
                  <TextField
                    className="input"
                    label='Your email'
                    onTrailingIconSelect={() => this.setState({email: ''})}
                  >
                    <Input
                      value={email}
                      onChange={(e) => this.setState({email: e.currentTarget.value})}
                    />
                  </TextField>
                  <TextField
                    className="input"
                    label='Your fullname'
                    onTrailingIconSelect={() => this.setState({fullName: ''})}
                  >
                    <Input
                      value={fullName}
                      onChange={(e) => this.setState({fullName: e.currentTarget.value})}
                    />
                  </TextField>
                  <TextField
                    className="input"
                    label='Your phone number'
                    onTrailingIconSelect={() => this.setState({phoneNumber: ''})}
                  >
                    <Input
                      value={phoneNumber}
                      onChange={(e) => this.setState({phoneNumber: e.currentTarget.value})}
                    />
                  </TextField>
                  <p>Need NDA first? E-mail us at <a className="text--link" href="mailto: estimation@coh.dev"
                                                     target="_blank">estimation@coh.dev</a></p>
                  <TextField
                    className="input"
                    label='Short of your project idea'
                    onTrailingIconSelect={() => this.setState({message: ''})}
                    textarea
                  >
                    <Input
                      value={message}
                      onChange={(e) => this.setState({message: e.currentTarget.value})}
                    />
                  </TextField>
                </div>
                <p className="m__t--30">Co.H is committed to processing the above information in order to contact you
                  and talk about your
                  project. Other information is used for statistical purposes and, from time to time, we would like to
                  contact you about our other products and services, as well as other content that may be of interest to
                  you. If you consent to contact you for this purposes, please tick below:</p>
                <div className={`checkbox m__t--20 ${isFirstCheck ? 'checkbox--active' : ''}`}
                     onClick={() => this.setState({isFirstCheck: !isFirstCheck})}>
                  <span className="checkbox__mark"/>
                  <label htmlFor="0">I agree to talk about my project with Co.H.</label>
                </div>
                <div className={`checkbox m__t--20 ${isSecondCheck ? 'checkbox--active' : ''}`}
                     onClick={() => this.setState({isSecondCheck: !isSecondCheck})}>
                  <span className="checkbox__mark"/>
                  <label htmlFor="1">I agree to receive other communications from Co.H.</label>
                </div>
                <p className="m__t--20">You can unsubscribe from these communications at any time. For more information
                  on how to
                  unsubscribe, our privacy practices please view our Privacy Policy.</p>
                <div className="d__flex justify__content--center">
                  <button
                    disabled={!isCanSubmit}
                    className={`btn btn--secondary btn--large m__t--30 min__w--220 ${!isCanSubmit ? 'btn--disabled' : ''}`}
                    onClick={this.handleSendEmail}
                  >
                    <span>Estimate Project</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default EstimationPage;
