import React from 'react';
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const HomeCarouselComponent = () => {
  return (
    <Slider {...settings}>
      <div className="section--case-study__content two__column">
        <div className="box__left" style={{backgroundImage: `url(/images/projects/saigonzoo.jpg)`}} />
        <div className="box__right">
          <h3>Saigon Zoo & Garden - Best zoo and garden park in the city</h3>
          <p>
            Saigon Zoo & Garden (SZ&G) is a NGO that wanna reinnovation old system,
            to new generation that could include online tickets, qr paid to check-in,
            eduation partner with schools. As a part of their growth plans the
            organisation’s leadership decided to revamp SZ&G’s core application.
          </p>
          <button className="btn btn--large btn--primary">
            <a href="/clients"><span>View this case study</span></a>
          </button>
          <div className="two__column">
            <div className="two__column__item">
              <h4>What we do here</h4>
              <ul>
                <li>Product Design</li>
                <li>UX Design</li>
                <li>React.js Development</li>
              </ul>
            </div>
            <div className="two__column__item">
              <h4>Statistics</h4>
              <ul>
                <li>4 core modules to do</li>
                <li>2 years working on</li>
                <li>4.8 points rating on new version</li>
                <li>4 members on board</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="section--case-study__content two__column">
        <div className="box__left" style={{backgroundImage: `url(/images/projects/videostreamshopping.jpg)`}} />
        <div className="box__right">
          <h3>Video Streaming integrated with Ecommerce, Make Shopping more emgagement and fun</h3>
          <p>
            Trang told us wanted to create a fashion application that would boost women's
            confidence with luxury collections as well as raise societal and environmental awareness.
            That’s how the idea for the e-commerce platform with stylish clothes integrated
            with Live Stream platform.
          </p>
          <button className="btn btn--large btn--primary">
            <a href="/clients"><span>View this case study</span></a>
          </button>
          <div className="two__column">
            <div className="two__column__item">
              <h4>What we do here</h4>
              <ul>
                <li>Product Design</li>
                <li>UX Design</li>
                <li>React Native Development</li>
                <li>Native Development</li>
              </ul>
            </div>
            <div className="two__column__item">
              <h4>Statistics</h4>
              <ul>
                <li>3 core modules to work</li>
                <li>6 months working on</li>
                <li>4 frameworks to integrated</li>
                <li>4 members on board</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="section--case-study__content two__column">
        <div className="box__left" style={{backgroundImage: `url(/images/projects/molli.jpg)`}} />
        <div className="box__right">
          <h3>Molli Printing House - Online printing services, and marketplace for baby and mom stuffs</h3>
          <p>
            Mr.Quoc came up with an idea for everyone who want to save their memories everytime and everywhere,
            take their photos alive and correct their home, through mobile and web application.
            He need a team to help he develop the concept and product.
          </p>
          <button className="btn btn--large btn--primary">
            <a href="/cients"><span>View this case study</span></a>
          </button>
          <div className="two__column">
            <div className="two__column__item">
              <h4>What we do here</h4>
              <ul>
                <li>Product Design</li>
                <li>UX Design</li>
                <li>React Native Development</li>
                <li>Mobile Native Development</li>
              </ul>
            </div>
            <div className="two__column__item">
              <h4>Statistics</h4>
              <ul>
                <li>3 core modules to work</li>
                <li>840 hours working on</li>
                <li>6k users in first releases</li>
                <li>4 members on board</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  )
}

export default HomeCarouselComponent;
