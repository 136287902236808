import React, { PureComponent } from 'react';

import { BUSINESS_INTELLIGENCE } from "../../constants";

import SubMenuComponent from "../../components/SubMenu";
import IndustriesComponent from "../../components/Industries";
import TestimonialsComponent from "../../components/Testimonials";
import BannerComponent from "../../components/Banner";
import FeaturesComponent from "../../components/Features";
import EstimationComponent from "../../components/Estimation";

class BusinessIntelligence extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="page page--sub">
        <BannerComponent isSubPage data={BUSINESS_INTELLIGENCE.BANNER}/>

        <div className="page__content bg--white">
          <SubMenuComponent/>
          <section className="section section--overview" id="overview">
            <h1 className="border border--center max__width--medium">BI Team will help you deliver a data-driven
              solutions to your business on time.</h1>
            <div className="max__width--medium text--center">
              <p className="text--gray m__b--10">
                According to the Global Business Intelligence Market 2016-2020 report, published by Technavio, the
                global BI market is forecast to grow at an annual rate of more than 10 percent by 2020. Increasing
                adoption of data analytics, guaranteeing data availability, and implementing cloud BI are the three
                major factors driving the market.
              </p>
              <p className="text--gray m__b--10">
                Although much of today’s hype is centered around big data and advanced analytics, as well as artificial
                intelligence and machine learning, Business Intelligence is still relevant for multiple reasons.
                Business intelligence (BI) is not a single technology. It’s a way of supporting outcome-oriented
                strategies by incorporating data-driven decision making.
              </p>
              <p className="text--gray m__b--10">
                BI technologies provide an accurate understanding of past events and current states and perform
                predictive analytics of business operations through their ability to handle large amounts of structured
                data. Whether for the purpose of identifying, developing or even creating new strategic business
                opportunities, BI is almost certainly going to be a crucial part of most of the biggest success stories
                currently being written.
              </p>
              <p className="text--gray m__b--10">
                Common functionalities of business intelligence technologies include reporting, data analytics, data
                mining, process mining, complex event processing, business performance management, benchmarking, text
                mining, predictive analytics, and prescriptive analytics.
              </p>
              <p className="text--gray m__b--10">
                Applying all the aforementioned methods to raw data is at the heart of Business Intelligence. Step by
                step, more valuable information can be extracted and incorporated into your businesses decision-making
                process.
              </p>
              <p className="text--gray m__b--10">
                Business Intelligence tools access and analyze data sets and aggregate analytical findings in the form
                of reports, summaries, dashboards, graphs, charts, and maps to provide detailed intelligence about the
                state of the business.
              </p>
              <p className="text--gray m__b--10">
                Insights gained through the use of BI technologies are what will allow your business to automatically
                evaluate the success of existing processes by looking at key metrics, determine areas in need of
                improvement, provide the much-needed justification for investing and pursuing new opportunities and,
                ultimately track all aspects of your business strategy moving forward, allowing you to evaluate progress
                at any point.
              </p>
            </div>
            <FeaturesComponent data={BUSINESS_INTELLIGENCE.FEATURES}/>
          </section>

          <IndustriesComponent/>
          <TestimonialsComponent/>
          <EstimationComponent data={BUSINESS_INTELLIGENCE.ESTIMATION}/>
        </div>
      </div>
    );
  }
}

export default BusinessIntelligence;
