import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const MENU = [
  {
    name: 'Services',
    path: 'services'
  },
  {
    name: 'Clients',
    path: 'clients'
  },
  {
    name: 'About us',
    path: 'about-us'
  },
  {
    name: 'Blogs',
    path: 'blogs'
  },
  {
    name: 'Careers',
    path: 'careers'
  },
];

const Menu = ({ active }) => {
  return (
    <div className="menu__wrapper">
      <ul className={`menu ${active === 'home' ? 'menu--home' : ''}`}>
        {
          MENU.map((item, index) => {
            return (
              <li key={index} className={`menu__item ${item.path === active ? 'menu__item--active' : ''}`}>
                <Link to={`/${item.path}`}>{item.name}</Link>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
};

export default Menu;
