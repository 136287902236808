import React from 'react';

const Avatar = ({avatar = 'default-avatar.png', size}) => {
  return (
    <div className="avatar" style={{'width': size, 'height': size}}>
      <img src={`/images/avatar/${avatar}`} alt="Avatar"/>
    </div>
  )
};

export default Avatar;
