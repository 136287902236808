import React from 'react';

const INDUSTRIES = [
  {
    img: '/images/healthcare.jpg',
    title: 'healthcare'
  },
  {
    img: '/images/lifestyle.jpg',
    title: 'lifestyle'
  },
  {
    img: '/images/NGOs.jpg',
    title: 'NGOs'
  },
  {
    img: '/images/saas.jpg',
    title: 'SaaS'
  },
  {
    img: '/images/hospatility.jpg',
    title: 'hospatility'
  },
  {
    img: '/images/ecommerce.jpg',
    title: 'Ecommerce'
  },
  {
    img: '/images/dating.jpg',
    title: 'dating'
  },
  {
    img: '/images/printing.jpg',
    title: 'Online printing'
  }
];


const IndustriesComponent = () => {
  return (
    <section className="section section--industry" id="industries">
      <h1 className="border border--center">Industries we excel at</h1>
      <p className="max__width--medium text--center">
        Our clients are businesses of different types and sizes. We developed projects for companies in a range
        of industries: finance, media, healthcare, education, government, real estate/property, social media,
        travel/transport, e-business, e-commerce, to name a few.
      </p>
      <div className="industry__list">
        {
          INDUSTRIES.map((item, index) => (
            <div className="industry__item" key={index} style={{backgroundImage: `url(${item.img})`}}>
              <h3>{item.title}</h3>
            </div>
          ))
        }
      </div>
    </section>
  )
}

export default IndustriesComponent;
