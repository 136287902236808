import React, { PureComponent } from 'react';

import { CLIENTS_PAGE } from '../../constants';

import EstimationComponent from "../../components/Estimation";
import BannerComponent from "../../components/Banner";
import ModalComponent from "../../components/Modal";

class ClientsPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      isOpenModal: false,
      projectsData: CLIENTS_PAGE.PROJECTS,
      projectSelected: CLIENTS_PAGE.PROJECTS[0],
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOpenModal = (projectSelected) => {
    this.setState({
      isOpenModal: true,
      projectSelected
    });
  };

  handleCloseModal = () => {
    this.setState({isOpenModal: false});
  };

  render() {
    const {isOpenModal, projectSelected, projectsData} = this.state;

    return (
      <div className="page page--clients">
        <BannerComponent data={CLIENTS_PAGE.BANNER}/>
        <div className="page__content bg--white">
          <section className="section__wrapper">
            <div className="section section--projects max__width">
              <div className="sidebar">
                {
                  CLIENTS_PAGE.CATEGORIES.map((item, index) =>
                    <button className="btn btn--medium btn--outline" key={index}>
                      <span>{item.name}</span>
                    </button>
                  )
                }
              </div>
              <div className="projects__container">
                <h2 className="border border--left">Projects</h2>
                <div className="project__list">
                  {
                    projectsData.map((item, index) => (
                      <div className="project__item animated fadeIn" key={index}
                           onClick={() => this.handleOpenModal(item)}>
                        <div className="project__item--img">
                          <i style={{backgroundImage: `url(/images/projects/${item.image})`}}/>
                        </div>
                        <h3>{item.name}</h3>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </section>
          <EstimationComponent data={CLIENTS_PAGE.ESTIMATION}/>
          {
            isOpenModal &&
            <ModalComponent
              isOpenModal={isOpenModal}
              onCloseModal={this.handleCloseModal}
              data={projectSelected}
            />
          }
        </div>
      </div>
    );
  }
}

export default ClientsPage;
