import React, { PureComponent } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import QuoteComponent from "../Quote";

class ModalComponent extends PureComponent {
  render() {
    const {
      isOpenModal,
      data,
      onCloseModal
    } = this.props;

    const {
      image,
      path,
      technologies,
      name,
      description,
      process,
      images,
      quote,
      statistics,
      scopes
    } = data;

    return (
      <Modal
        className="modal modal--project"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpenModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="modal__container">
          <section className="modal__header text--center background" style={{backgroundImage: `url(/images/projects/${image})`}}>
            <button className="modal__close" onClick={onCloseModal}>
              <i className="icon-close" />
            </button>
            <div className="modal__header__inner">
              <h4>
                {
                  technologies.map((tech, index) => (
                    <span>{index !== 0 && ', '}{tech}</span>
                  ))
                }
              </h4>
              <h2>{name}</h2>
              <p>{description}</p>
            </div>
          </section>
          <section className="processes">
            {
              process.map((item, index) => (
                <div className="process__item" key={index}>
                  <h4>
                    <span>0{index + 1}</span>
                    <b>{item.title}</b>
                  </h4>
                  <p>{item.description}</p>
                  <ul>
                    {
                      item.listing.map((l, i) => (
                        <li key={i}>
                          <b>{i + 1}</b>
                          <p>{l}</p>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))
            }
          </section>
          <section className="images">
            {
              images.map((item, index) => (
                <div className="img__item" key={index}>
                  <img src={`images/projects/${path}/${item}`} alt="Molli Images"/>
                </div>
              ))
            }
          </section>
          <QuoteComponent data={quote}/>
          <section className="statistics">
            {
              statistics.map((item, index) => (
                <div className="statistic__item" key={index}>
                  <h1 className="text--secondary">{item.value}</h1>
                  <div className="m__l--10">
                    <h3>{item.name}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))
            }
          </section>
          <section className="scopes">
            <h1 className="border border--center">Scope of project</h1>
            <ul>
              {
                scopes.map((item, index) => (
                  <li key={index}>
                    <i className={`icon-${item.icon}`}/>
                    <h3>{item.name}</h3>
                  </li>
                ))
              }
            </ul>
          </section>
        </div>
      </Modal>
    )
  }
};


export default ModalComponent;
